import React from 'react';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { DesignerFavoriteProps } from 'modals/designer-favorite/designer-favorite.props';
import { Button } from 'core/button';
import { Thumbnail } from 'core/thumbnail';
import { FlexContainer } from 'layout/flex-container';
import { useModalClose } from 'context/modal.context';

export const DesignerFavoriteModal = (props: DesignerFavoriteProps) => {
  const { closeLastOpened } = useModalClose();

  return (
    <Grid
      justifyContent="center"
      justifyItems="center"
      width="100%"
      rowGap="s1"
      padding={{ lg: 's5', md: 's3', sm: 's2' }}>
      <Thumbnail src={props.photoUrl} round={true} height="6rem" width="6rem" />
      <Typography variant="h4" tx="favoriteModal:title" align="center" />

      <Typography
        tx="favoriteModal:description"
        txValues={{ designer: props.designerName }}
        color="grey"
        align="center"
      />

      <FlexContainer
        justifyContent={'stretch'}
        paddingTop={{ lg: 's3', md: 's2' }}>
        <Button
          tx="favoriteModal:doneBtn"
          width="100%"
          onClick={closeLastOpened}
        />
      </FlexContainer>
    </Grid>
  );
};
