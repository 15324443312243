import styled, { css } from 'styled-components';
import { Icon } from 'core/icon';
import { FloatingContainer } from 'layout/floating-container';
import { StyledInput } from 'core/text-input/text-input.styles';

export const StyledDatePickerContainer = styled.div`
  position: relative;
  user-select: none;

  ${StyledInput} {
    cursor: pointer;
  }
`;

export const StyledCalendarIcon = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 1rem;
  pointer-events: none;
`;

export const StyledDatePickerPopup = styled(FloatingContainer)`
  ${({ theme }) => css`
    top: calc(100% + 4px);
    border: 1px solid ${theme.colors.lightGrey};
    border-radius: ${theme.borderRadius};
    box-shadow: ${theme.shadow};
    padding: ${theme.spacing.s2};
    z-index: 1;
    left: unset;
    right: 0;
    background-color: ${theme.colors.white};
  `}
`;

export const StyledPopupDivider = styled.div`
  ${({ theme }) => css`
    height: 1px;
    width: 100%;
    background-color: ${theme.colors.lightGrey};
  `}
`;

export const StyledDay = styled.div<{ selected?: boolean; active?: boolean }>`
  ${({ theme, selected, active }) => css`
    padding: 4px;
    background-color: ${selected ? theme.colors.primary : ''};
    border-radius: 50%;
    opacity: ${active ? 1 : 0.5};
    pointer-events: ${active ? 'all' : 'none'};
    cursor: ${active ? 'pointer' : 'not-allowed'};
    width: 3rem;
    height: 3rem;
  `}
`;
