import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { useModal } from 'context/modal.context';
import { ReachVideoModal } from 'modules/shop/reach/modals';
import { StyledOpaqueImageContainer } from 'modules/shop/reach/reach.styles';

export const DesignerSection = () => {
  const { showModal } = useModal({
    template: <ReachVideoModal url="https://youtu.be/rifFpRkH2l4" />,
    width: '90vw',
    height: { lg: '50vw', md: '90vw' },
  });

  return (
    <StyledOpaqueImageContainer
      src={{
        lg: 'pages/reach/influencer-1.jpg',
        md: 'pages/reach/influencer-small-1.jpg',
        sm: 'pages/reach/influencer-small-1.jpg',
      }}
      aspectRatio={{ lg: '2:1', md: '1:1' }}>
      <StackedContainer
        justifyContent="center"
        justifyItems="center"
        height="100%"
        alignContent="center"
        gap="unset">
        <Typography
          variant="h3"
          type="p"
          tx="reach:designer.description"
          textStyle="regular"
          color="white"
          align="center"
          maxWidth="80rem"
          paddingBottom={{ lg: 's3', md: 's2' }}
        />

        <Typography
          tx="reach:designer.name"
          color="white"
          align="center"
          variant="h3"
          type="p"
        />

        <Typography
          tx="reach:designer.profession"
          color="white"
          align="center"
          textStyle="regular"
          paddingBottom={{ lg: 's5', md: 's4' }}
        />

        <Button
          tx="reach:designer.btn"
          color="white"
          width="15rem"
          icon={<Icon name="play-full" color="black" size="2rem" />}
          onClick={showModal}
        />
      </StackedContainer>
    </StyledOpaqueImageContainer>
  );
};
