import React from 'react';
import { Grid } from 'layout/grid';
import { ReachPageProps } from 'modules/shop/reach/reach.props';
import { SKU } from 'types/product.type';
import { ProductCard } from 'layout/product-card';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';

export const ShopSection = (props: ReachPageProps) => {
  return (
    <StackedContainer
      verticalPadding={{ lg: 's6', md: 's5' }}
      rowGap={{ lg: 's5', md: 's4' }}>
      <Typography
        variant="h2"
        tx="reach:shop.title"
        color="opaqueGreen"
        textStyle="regular"
        align="center"
      />

      <Grid
        columns={{ lg: 4, md: 3, sm: 2 }}
        rowGap={{ lg: 's5', md: 's4' }}
        padding="unset">
        {props.skus.map((sku: SKU) => (
          <ProductCard key={sku.sku} product={sku} aspectRatio="1:1" />
        ))}
      </Grid>
    </StackedContainer>
  );
};
