import { CheckoutShipping } from 'modules/shop/checkout/checkout.types';
import { Address } from 'types/user.type';
import { extractNamePartsFromName } from 'modules/shop/cart/cart.adapters';
import { GiftCardRecord } from 'types/gift-card.type';
import { GiftCardTransactionRecord } from 'services/api/gitf-card/gift-card.types';

export const convertCheckoutAddressToAddress = (
  checkoutAddress: CheckoutShipping,
): Address => {
  const { firstName, lastName } = extractNamePartsFromName(
    checkoutAddress.name,
  );

  return {
    ...checkoutAddress,
    address: checkoutAddress.streetAddress,
    firstName,
    lastName,
  } as Address;
};

export const convertGiftCardRecordsToGiftCardTransactionRecords = (
  records: GiftCardRecord[],
): GiftCardTransactionRecord[] => {
  return records.map((record: GiftCardRecord) => ({
    productId: record.productId,
    amount: record.amount,
    message: record.message,
    recipientEmail: record.recipientEmail,
    recipientName: record.recipientName,
    sendDate: record.sendDate,
    senderName: record.senderName,
  }));
};

export const convertUserAddressToLine = (address: Address): string => {
  const unit = address.unit ? ` #${address.unit}` : '';
  return `${address.street}${unit}, ${address.city}, ${address.state} ${address.zip}`;
};
