import { environment } from 'config/environment';
import { SKU } from 'types/product.type';
import { fetchAsync } from 'assets/api/common';
import meanBy from 'lodash/meanBy';
import uniqBy from 'lodash/uniqBy';
import { Review } from 'types/review.type';

export const fetchSkus = async (): Promise<SKU[]> => {
  const { success, products, productLeadTime } = await fetchAsync(
    `${environment.API_URL}/products/skus`,
  );

  if (success) {
    return products.filter(item => item.status === 'Active');
  }

  return [];
};

export const fetchReviews = async (skus: string[], pageSize: number = 20) => {
  try {
    let reviews: Review[] = [];

    for (const sku of skus) {
      const { response } = await fetchAsync(
        `https://api.yotpo.com/v1/widget/${environment.YOTPO_KEY}/products/${sku}/reviews.json?page=1&per_page=${pageSize}`,
      );

      reviews = reviews.concat(
        response.reviews.map(review =>
          Object.assign({}, review, {
            product: response?.products[0],
          }),
        ),
      );
    }

    const uniq = uniqBy(reviews, 'id');

    return {
      reviews: uniq,
      total: meanBy(uniq, 'score'),
    };
  } catch (e) {
    return {
      reviews: [],
      total: 0,
    };
  }
};
