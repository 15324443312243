import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  StyledCloseButton,
  StyledGiftHeader,
  StyledGiftMessageContainer,
  StyledRightAlignedBlock,
  StyledRowContent,
  StyledShipmentRow,
  StyledSpecsContainer,
} from 'layout/shipment-row/shipment-row.styles';
import { ShipmentRowProps } from 'layout/shipment-row/shipment-row.props';
import { Thumbnail } from 'core/thumbnail';
import { Typography } from 'core/typography';
import { formatCurrency } from 'utils/formaters';
import { BoxContainer } from 'layout/box-container';
import Link from 'next/link';
import { Grid } from 'layout/grid';
import { Progress } from 'core/progress';
import { StockAssociationStatus } from 'types/order.type';
import { ThemeColor } from 'styles/theme';
import { Checkbox } from 'core/checkbox';
import { Icon } from 'core/icon';
import { Tooltip } from 'core/tooltip';
import { StackedContainer } from 'layout/stacked-container';
import { ImageContainer } from 'layout/image-container';
import { TextArea } from 'core/text-area';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { FlexContainer } from 'layout/flex-container';
import { ShipmentRowPresets } from 'layout/shipment-row/shipment-row.presets';

const getStatusColor = (status: StockAssociationStatus): ThemeColor => {
  switch (status) {
    case StockAssociationStatus.Cancelled:
    case StockAssociationStatus.ReturnCanceled:
    case StockAssociationStatus.Refunded:
      return 'red';
    case StockAssociationStatus.Completed:
      return 'black';
    default:
      return 'grey';
  }
};

const GiftTextArea = ({ giftMessage, disabled, onGiftMessageChange }) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage(giftMessage);
  }, [giftMessage]);

  return (
    <>
      <TextArea
        placeholderTx="cart:giftMessagePlaceholder"
        maxLength={140}
        value={giftMessage}
        disabled={disabled}
        onTextChange={setMessage}
        onBlur={({ target: { value } }) => onGiftMessageChange(value)}
      />
      <Typography
        tx="cart:giftCharactersCount"
        txValues={{ count: message.length || 0 }}
        color="grey"
      />
    </>
  );
};

const GiftTooltip = () => (
  <StackedContainer padding="unset">
    <ImageContainer src="common/plain-box.png" aspectRatio="1:0.66" />
    <Typography
      tx="cart:giftTooltip"
      variant="p3"
      color="grey"
      align="center"
    />
  </StackedContainer>
);

export const ShipmentRow = (props: ShipmentRowProps) => {
  const {
    data,
    status,
    statusHidden,
    stocksCount,
    progressPercentage,
    progressVisible,
    removable,
    canEditGiftInfo,
    giftable,
    showGiftPreview,
    isGift,
    canEnterGiftMessage,
    canNavigateToProduct,
    onRemove,
    onIsGiftChange,
    onGiftMessageChange,
  } = Object.assign({}, ShipmentRowPresets, props);

  const productPageUrl = `/products/${data.product?.id}/${data.product?.fullUrlName}/${data.sku}`;

  const statusProgress = useMemo(
    () => (
      <Grid
        columns={progressVisible ? 'repeat(3, max-content)' : '1fr'}
        padding="unset"
        alignItems="center"
        gap="1rem">
        {!!status && !statusHidden && (
          <Typography tx={status} color={getStatusColor(status)} />
        )}

        {progressVisible && (
          <>
            <Progress value={progressPercentage} width="10rem" />
            <Typography variant="p3" text={`${progressPercentage}%`} />
          </>
        )}
      </Grid>
    ),
    [progressPercentage, statusHidden, progressVisible, status],
  );

  const onRemoveClick = useCallback(
    event => {
      event.preventDefault();
      onRemove(data);
    },
    [onRemove, data],
  );

  const onLinkClick = useCallback(
    event => {
      if (!canNavigateToProduct) {
        event.preventDefault();
      }
    },
    [canNavigateToProduct],
  );

  return (
    <Grid padding="unset" gap="s2">
      <Link
        href="/products/[id]/[skuInfo]/[sku]"
        as={productPageUrl.toLowerCase()}>
        <a onClick={onLinkClick}>
          <StyledShipmentRow>
            <Thumbnail
              src={data.thumbnail}
              size={{ lg: '8rem', sm: '6rem' }}
              borderRadius="unset"
            />

            <StyledRowContent removable={removable}>
              <StyledSpecsContainer>
                <Typography text={data.name} />
                <Typography text={data.designerName} color="grey" />
                <Typography text={`${data.color}, ${data.size}`} color="grey" />
                <BoxContainer hidden={{ lg: true, sm: false }}>
                  <Typography text={formatCurrency(data.price)} color="grey" />

                  {!!stocksCount && stocksCount <= 5 && (
                    <Typography
                      tx="common.skusLeft"
                      txValues={{ amount: stocksCount }}
                      color="red"
                    />
                  )}

                  {statusProgress}
                </BoxContainer>

                {giftable && (
                  <StyledGiftMessageContainer>
                    <StyledGiftHeader>
                      <Checkbox
                        labelTx="cart:isGift"
                        checked={isGift}
                        onChange={onIsGiftChange}
                        disabled={!canEditGiftInfo}
                      />

                      <Tooltip
                        content={<GiftTooltip />}
                        popupWidth="24rem"
                        roundContainer={true}>
                        <Icon name="info" />
                      </Tooltip>
                    </StyledGiftHeader>

                    {canEnterGiftMessage && isGift && (
                      <GiftTextArea
                        giftMessage={data.giftMessage}
                        disabled={!canEditGiftInfo}
                        onGiftMessageChange={onGiftMessageChange}
                      />
                    )}
                  </StyledGiftMessageContainer>
                )}

                {showGiftPreview && data.isGift && (
                  <>
                    <FlexContainer verticalPadding="s2">
                      <TypographyWithIcon
                        tx="cart:isGift"
                        icon={<Icon name="gift" />}
                      />

                      <Tooltip
                        content={<GiftTooltip />}
                        popupWidth="24rem"
                        roundContainer={true}>
                        <Icon name="info" left="8px" />
                      </Tooltip>
                    </FlexContainer>

                    {!!data.giftMessage && (
                      <div>
                        <Typography tx="cart:giftMessage" color="grey" />
                        <Typography text={data.giftMessage || ''} />
                      </div>
                    )}
                  </>
                )}
              </StyledSpecsContainer>

              <StyledRightAlignedBlock>
                <Typography text={formatCurrency(data.price)} color="grey" />
                {!!stocksCount && stocksCount <= 5 && (
                  <Typography
                    tx="common.skusLeft"
                    txValues={{ amount: stocksCount }}
                    color="red"
                  />
                )}

                {statusProgress}
              </StyledRightAlignedBlock>

              {removable && (
                <StyledCloseButton
                  name="close"
                  onClick={onRemoveClick}
                  color="lightGrey"
                  size="1.2rem"
                />
              )}
            </StyledRowContent>
          </StyledShipmentRow>
        </a>
      </Link>
    </Grid>
  );
};
