import React from 'react';
import { ShareProps } from 'modals/share/share.props';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { trackAnalyticsEvent } from 'utils/analytics';
import { Thumbnail } from 'core/thumbnail';
import ChatIcon from 'assets/images/pages/refer/share-chat.png';
import { BoxContainer } from 'layout/box-container';
import {
  EmailShareButton,
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { environment } from 'config/environment';
import MessengerIcon from 'assets/images/pages/refer/share-messenger.png';
import TwitterIcon from 'assets/images/pages/refer/share-twitter.png';
import FacebookIcon from 'assets/images/pages/refer/share-facebook.png';
import EmailIcon from 'assets/images/pages/refer/share-email.png';
import { SharePresets } from 'modals/share/share.presets';

export const ShareModal = (props: ShareProps) => {
  const {
    titleTx,
    descriptionTx,
    message,
    link,
    analyticsIdentifier,
  } = Object.assign({}, SharePresets, props);

  return (
    <Grid
      justifyContent="center"
      justifyItems="center"
      width="100%"
      rowGap="s1"
      padding={{ lg: 's5', md: 's3', sm: 's2' }}>
      <Typography variant="h2" tx={titleTx} align="center" />
      <Typography
        tx={descriptionTx}
        color="grey"
        align="center"
        maxWidth="23.4rem"
      />

      <Grid
        padding="unset"
        gap="s1"
        columns="repeat(auto-fit, 3.4rem)"
        marginTop="s2"
        justifyContent="center"
        justifyItems="center"
        columnGap={{ lg: 's4', md: 's2', sm: 's1' }}>
        <a
          href={`sms:?&body=${message} ${link}`}
          target="_blank"
          onClick={() =>
            trackAnalyticsEvent(`${analyticsIdentifier} Share SMS`)
          }>
          <Thumbnail src={ChatIcon} height="3.4rem" width="unset" />
        </a>

        <BoxContainer hidden={{ lg: true, md: false }}>
          <FacebookMessengerShareButton
            url={link}
            appId={environment.FACEBOOK_APP_ID}
            onClick={() =>
              trackAnalyticsEvent(`${analyticsIdentifier} Share Messenger`)
            }>
            <Thumbnail src={MessengerIcon} height="3.4rem" width="unset" />
          </FacebookMessengerShareButton>
        </BoxContainer>

        <TwitterShareButton
          url={link}
          title={message}
          via="gantridesigns"
          hashtags={['gantrilight']}
          onClick={() =>
            trackAnalyticsEvent(`${analyticsIdentifier} Share Twitter`)
          }>
          <Thumbnail src={TwitterIcon} height="3.4rem" width="unset" />
        </TwitterShareButton>

        <FacebookShareButton
          url={link}
          quote={message}
          onClick={() =>
            trackAnalyticsEvent(`${analyticsIdentifier} Share Facebook`)
          }>
          <Thumbnail src={FacebookIcon} height="3.4rem" width="unset" />
        </FacebookShareButton>

        <EmailShareButton url={link} body={message}>
          <Thumbnail
            src={EmailIcon}
            height="3.4rem"
            width="unset"
            onClick={() =>
              trackAnalyticsEvent(`${analyticsIdentifier} Share Email`)
            }
          />
        </EmailShareButton>
      </Grid>
    </Grid>
  );
};
