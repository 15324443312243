import React from 'react';
import { Page } from 'core/page';
import { NextPage } from 'next';
import { useGiftCard } from 'context/gift-card.context';
import {
  CardDesigns,
  CardUpsellSection,
  HeroSection,
  HowItWorksSection,
  QuestionsSection,
} from 'modules/shop/gift-cards/sections';

export const GiftCardsPage: NextPage = () => {
  const [{ designs }] = useGiftCard();

  return (
    <Page headerLocked={true} hero={<HeroSection />}>
      <CardDesigns designs={designs.slice(0, 3)} />
      <HowItWorksSection />
      <QuestionsSection />
      <CardUpsellSection />
    </Page>
  );
};
