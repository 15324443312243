import React from 'react';
import { Typography } from 'core/typography';
import {
  StyledShippingBannerSmaller,
  StyledShippingBlock,
} from './shipping-banner-smaller.styles';
import { Icon } from 'core/icon/icon';

export const ShippingBannerSmaller = () => (
  <StyledShippingBannerSmaller>
    <StyledShippingBlock>
      <Icon name="trunk" color="grey" size="4rem" />
      <Typography variant="p3" color="grey" tx="common.shipping.free" />
    </StyledShippingBlock>

    <StyledShippingBlock>
      <Icon name="returns" color="grey" size="4rem" />
      <Typography variant="p3" color="grey" tx="common.shipping.returns" />
    </StyledShippingBlock>

    <StyledShippingBlock>
      <Icon name="live-tracking" color="grey" size="4rem" />
      <Typography variant="p3" color="grey" tx="common.shipping.liveTracking" />
    </StyledShippingBlock>
  </StyledShippingBannerSmaller>
);
