import { ColoredContainerProps } from './colored-section.props';

export const ColoredContainerPresets: ColoredContainerProps = {
  titleColor: 'white',
  descriptionColor: 'translucentWhite',
  backgroundColor: 'primary',
  titleVariant: 'h2',
  descriptionVariant: 'h3',
  titleAlign: 'center',
  descriptionAlign: 'center',
  maxWidth: '75.6rem',
  gap: { lg: 's4', sm: 's3' },
  titleTextStyle: 'bold',
  descriptionTextStyle: 'regular',
  verticalPadding: { lg: 's6', md: 's5' },
  horizontalPadding: { lg: 's3', md: 's2', sm: 's1' },
};
