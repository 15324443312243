import React, { useEffect, useState } from 'react';
import { Page } from 'core/page';
import { Hero } from 'layout/hero';
import { useAuth } from 'context/auth.context';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Formik } from 'formik';
import { TextInput } from 'core/text-input';
import { Button } from 'core/button';
import { useNotification } from 'context/notification.context';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { environment } from 'config/environment';
import { Icon } from 'core/icon';
import GoogleLogin from 'react-google-login';
import {
  AuthenticateRequest,
  FacebookSignInRequest,
  GoogleSignInRequest,
  UserData,
} from 'services/api/auth';
import { api, RequestResponse } from 'services/api';
import { useSession } from 'src/common/hooks';
import { useRouter } from 'next/router';
import { extractNamePartsFromName } from 'modules/shop/cart/cart.adapters';
import { AcceptReferralFormSchema } from 'modules/shop/accept-referral/accept-referral-form.schema';
import { useModal } from 'context/modal.context';
import { EnjoyGiftModal } from 'modules/shop/accept-referral/modals';

export const initial = {
  fullName: '',
  email: '',
  password: '',
};

export const AcceptReferralPage = ({ referrer }) => {
  const [{ campaignInfo }] = useAuth();
  const {
    notify,
    notifyError,
    showPreloader,
    hidePreloader,
  } = useNotification();
  const [processing, setProcessing] = useState(false);
  const { updateSessionData } = useSession();
  const { query, push: routerPush } = useRouter();
  const [{ authenticated }] = useAuth();
  const { showModal } = useModal({
    template: <EnjoyGiftModal />,
    width: '36rem',
  });

  useEffect(() => {
    if (authenticated) {
      routerPush('/');
    }
  }, [authenticated]);

  const signIn = async (request: AuthenticateRequest) => {
    const {
      success,
      data,
      error,
    }: RequestResponse<UserData> = await api.auth.authenticate(request);
    if (success) {
      await updateSessionData({
        user: data.data,
        token: data.token,
        firebaseToken: data.firebaseToken,
      });

      if (data.gotCredit) {
        await showModal();
      }

      await routerPush('/');
    } else {
      notifyError({
        message: error.message,
        isError: true,
      });
    }
  };

  const emailSignIn = async ({ email, password, fullName }: typeof initial) => {
    const { firstName, lastName } = extractNamePartsFromName(fullName);

    const request: AuthenticateRequest = {
      email,
      password,
      referralHash: String(query.code),
      fullSignUp: true,
      firstName,
      lastName,
      ...campaignInfo,
      source: 'Sign Up - Referral Accept',
      signUpEventName: 'Sign Up - Referral Accept',
    };

    setProcessing(true);

    await signIn(request);

    setProcessing(false);
  };

  const googleSignIn = async (googleInfo: GoogleSignInRequest) => {
    const request: AuthenticateRequest = {
      type: 'google_v2',
      googleInfo,
      referralHash: String(query.code),
      ...campaignInfo,
      source: 'Sign Up - Referral Accept',
      signUpEventName: 'Sign Up - Referral Accept',
    };

    showPreloader();

    await signIn(request);

    hidePreloader();
  };

  const facebookSignIn = async (facebookInfo: FacebookSignInRequest) => {
    const request: AuthenticateRequest = {
      type: 'facebook_v2',
      facebookInfo,
      referralHash: String(query.code),
      ...campaignInfo,
      source: 'Sign Up - Referral Accept',
      signUpEventName: 'Sign Up - Referral Accept',
    };

    showPreloader();

    await signIn(request);

    hidePreloader();
  };

  const onError = () => {
    notify({
      messageTx: 'errors.authentication',
      isError: true,
    });
  };

  return (
    <Page
      headerLocked={true}
      hero={
        <Hero
          backgroundImageSrc="pages/refer/banner.jpg"
          height={{ lg: '25vw', md: '50vw' }}
          scrollable={false}
        />
      }>
      <Grid
        columns={{ lg: '1fr 4fr 2fr 4fr 1fr', md: '1fr' }}
        verticalPadding={{ lg: 's6', md: 's5' }}
        horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}
        rowGap={{ md: 's5', sm: 's4' }}>
        <Cell column={{ lg: 2, md: 1 }}>
          <StackedContainer gap="s1" padding="unset">
            <Typography
              tx="acceptReferral:title"
              variant="h2"
              type="h1"
              txValues={{ referrer }}
            />

            <Typography
              tx="acceptReferral:description"
              variant="p2"
              color="grey"
            />
          </StackedContainer>
        </Cell>
        <Cell column={{ lg: 4, md: 1 }}>
          <Formik
            initialValues={{ ...initial, email: String(query.trueEmail ?? '') }}
            enableReinitialize={true}
            validationSchema={AcceptReferralFormSchema}
            validateOnMount={true}
            validateOnChange={true}
            onSubmit={emailSignIn}>
            {props => (
              <form onSubmit={props.handleSubmit}>
                <StackedContainer gap={{ lg: 's4', sm: 's3' }} padding="unset">
                  <Typography tx="acceptReferral:form.title" variant="h4" />

                  <Grid columns={2} padding="unset" gap="s1">
                    <FacebookLogin
                      appId={environment.FACEBOOK_APP_ID}
                      fields="first_name,last_name,name,email,link"
                      autoLoad={false}
                      callback={response => {
                        facebookSignIn({
                          accessToken: response.accessToken,
                          profile: {
                            facebookId: response.id,
                            email: response.email,
                            firstName: response.first_name,
                            lastName: response.last_name,
                          },
                        });
                      }}
                      render={facebookProps => (
                        <Button
                          tx="signIn:facebook"
                          color="#39579a"
                          textColor="white"
                          width="unset"
                          icon={<Icon name="facebook-black" color="white" />}
                          disabled={facebookProps.isDisabled}
                          onClick={facebookProps.onClick}
                        />
                      )}
                      onFailure={onError}
                    />

                    <GoogleLogin
                      clientId={environment.GOOGLE_CLIENT_ID}
                      autoLoad={false}
                      isSignedIn={false}
                      render={googleProps => (
                        <Button
                          tx="signIn:google"
                          color="#4285f4"
                          textColor="white"
                          width="unset"
                          icon={
                            <Icon name="google" color="white" size="2rem" />
                          }
                          onClick={googleProps.onClick}
                        />
                      )}
                      onSuccess={(info: any) => {
                        googleSignIn({
                          accessToken: info.accessToken,
                          tokenId: info.tokenId,
                          profile: info.profileObj,
                        });
                      }}
                      onFailure={({ error }) => {
                        if (error !== 'idpiframe_initialization_failed') {
                          onError();
                        }
                      }}
                    />
                  </Grid>
                  <TextInput
                    placeholderTx="acceptReferral:form.fullName"
                    name="fullName"
                    value={props.values.fullName}
                    error={props.errors.fullName}
                    touched={props.touched.fullName}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />

                  <TextInput
                    placeholderTx="acceptReferral:form.email"
                    name="email"
                    value={props.values.email}
                    error={props.errors.email}
                    touched={props.touched.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />

                  <TextInput
                    placeholderTx="acceptReferral:form.password"
                    name="password"
                    type="password"
                    value={props.values.password}
                    error={props.errors.password}
                    touched={props.touched.password}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                  />

                  <Button
                    tx="acceptReferral:form.signUpBtn"
                    type="submit"
                    processing={processing}
                    disabled={!props.isValid}
                    onClick={props.handleSubmit}
                  />

                  <StackedContainer
                    padding="unset"
                    flow="column"
                    gap="4px"
                    justifyItems="center"
                    justifyContent="center">
                    <Typography
                      variant="p3"
                      type="span"
                      color="blue"
                      align="center"
                      text="By proceeding, you agree to our "
                    />

                    <Typography
                      variant="link"
                      fontSize="1.2rem"
                      type="span"
                      color="blue"
                      align="center"
                      textStyle="bold"
                      text="terms & policy"
                      href="/legal/terms"
                    />
                  </StackedContainer>
                </StackedContainer>
              </form>
            )}
          </Formik>
        </Cell>
      </Grid>
    </Page>
  );
};
