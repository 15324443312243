import React from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { QA } from 'types/user.type';
import { StackedContainer } from 'layout/stacked-container';

export interface QaQuestionsProps {
  questions: QA[];
  backgroundColor: string;
}

export const QaQuestionsSection = (props: QaQuestionsProps) => {
  const { questions, backgroundColor } = props;

  return (
    <Grid
      columns={{ lg: '3fr 6fr 3fr', md: '1fr' }}
      backgroundColor={backgroundColor}
      verticalPadding={{ lg: 's6', md: 's5' }}>
      <Cell column={{ lg: 2, md: 1 }}>
        <Typography tx="storefront:qa.title" variant="h2" />

        <StackedContainer
          padding="unset"
          marginTop={{ lg: 's4', md: 's3' }}
          gap={{ lg: 's4', md: 's3' }}>
          {questions.map((question: QA) => (
            <StackedContainer key={question.qaQuestion} padding="unset">
              <Typography variant="h3" text={question.qaQuestion} />

              <Typography
                text={question.qaAnswer}
                color="grey"
                whiteSpace="pre-wrap"
              />
            </StackedContainer>
          ))}
        </StackedContainer>
      </Cell>
    </Grid>
  );
};
