import styled, { css } from 'styled-components';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { StyledImageContainer } from 'layout/image-container/image-container.styles';
import { media } from 'styles/media';

export const StyledCloseButtonContainer = styled.div`
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 1;

  ${media.lessThan('md')`
      top:1.2rem;
      right:1.2rem;
   `}
`;

export const StyledSelectGiftCardContainer = styled.div`
  ${StyledCard} {
    max-width: unset;
    border-radius: 2rem;
    cursor: unset;

    ${media.lessThan('sm')`
       border-radius: 1.2rem;
    `}
  }

  &:hover ${StyledImageContainer} {
    opacity: 1;
  }
`;
