import React, { useCallback } from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { Thumbnail } from 'core/thumbnail';
import BuildColorsImage from 'assets/images/pages/reach/built-colors.png';
import SoftWhiteImage from 'assets/images/pages/reach/built-softwhite.png';
import EnergyImage from 'assets/images/pages/reach/built-energy.png';
import SmoothDimmingImage from 'assets/images/pages/reach/built-dimming.png';
import { StackedContainer } from 'layout/stacked-container';
import { FlexContainer } from 'layout/flex-container';
import { ResolutionAwareProp } from 'types/resolution-aware-prop.type';
import { Tabs } from 'core/tabs';
import { TabConfig } from 'core/tabs/tabs.props';
import { BoxContainer } from 'layout/box-container';

const tabsConfig: TabConfig[] = [
  {
    headingTx: 'reach:details.comparison.palm.title',
    content: (
      <div>
        <ImageContainer
          src="pages/reach/desk-small-1.png"
          aspectRatio="1:0.7"
          backgroundColor="#bf946b"
          objectFit="contain"
        />

        <Typography
          tx="reach:details.comparison.palm.title"
          color="sunrise"
          align="center"
          paddingTop="s1"
        />

        <Typography
          tx="reach:details.comparison.palm.description"
          color="white"
          align="center"
        />
      </div>
    ),
    width: 28,
  },
  {
    headingTx: 'reach:details.comparison.traditional.titleSmall',
    content: (
      <div>
        <ImageContainer
          src="pages/reach/desk-small-2.png"
          aspectRatio="1:0.69"
          backgroundColor="#bf946b"
          objectFit="contain"
        />

        <Typography
          tx="reach:details.comparison.traditional.title"
          color="sunrise"
          align="center"
          paddingTop="s1"
        />

        <Typography
          tx="reach:details.comparison.traditional.description"
          color="white"
          align="center"
        />
      </div>
    ),
    width: 65,
  },
];

export const DetailsSection = () => {
  const renderColorsInfo = useCallback(
    (hidden: ResolutionAwareProp<boolean>) => {
      return (
        <Grid columns={2} padding="unset" hidden={hidden} rowGap="s3">
          <div>
            <Thumbnail src={BuildColorsImage} size="3.2rem" border="unset" />
            <Typography
              tx="reach:details.trueColors.title"
              color="sunrise"
              variant="p1"
            />
            <Typography
              tx="reach:details.trueColors.description"
              color="white"
              maxWidth="19rem"
            />
          </div>

          <div>
            <Thumbnail src={SoftWhiteImage} size="3.2rem" border="unset" />
            <Typography
              tx="reach:details.softWhite.title"
              color="sunrise"
              variant="p1"
            />
            <Typography
              tx="reach:details.softWhite.description"
              color="white"
              maxWidth="19rem"
            />
          </div>

          <div>
            <Thumbnail src={EnergyImage} size="3.2rem" border="unset" />
            <Typography
              tx="reach:details.energy.title"
              color="sunrise"
              variant="p1"
            />
            <Typography
              tx="reach:details.energy.description"
              color="white"
              maxWidth="19rem"
            />
          </div>

          <div>
            <Thumbnail src={SmoothDimmingImage} size="3.2rem" border="unset" />
            <Typography
              tx="reach:details.smoothDimming.title"
              color="sunrise"
              variant="p1"
            />
            <Typography
              tx="reach:details.smoothDimming.description"
              color="white"
              maxWidth="19rem"
            />
          </div>
        </Grid>
      );
    },
    [],
  );

  return (
    <StackedContainer
      horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}
      verticalPadding={{ lg: 's6', md: 's5' }}
      backgroundColor="#bf946b"
      gap={{ lg: 's6', md: 's5' }}>
      <Grid
        columns={{ lg: '1fr 5fr 1fr 4fr 1fr', md: '1fr' }}
        gap="unset"
        padding="unset"
        alignItems="center"
        rowGap={{ lg: 'unset', md: 's4' }}>
        <Cell column={{ lg: 2, md: 1 }} row={{ lg: 1, md: 2 }}>
          <ImageContainer
            src="pages/reach/built-photo.jpg"
            aspectRatio="0.8:1"
            backgroundColor="#bf946b"
          />
        </Cell>

        <Cell column={{ lg: 4, md: 1 }}>
          <StackedContainer rowGap={{ lg: 's5', md: 's4' }} padding="unset">
            <div>
              <Typography
                tx="reach:details.heading"
                color="white"
                variant="h5"
                align={{ lg: 'left', md: 'center' }}
              />
              <Typography
                tx="reach:details.title"
                color="sunrise"
                variant="h2"
                textStyle="regular"
                paddingTop="s1"
                paddingBottom={{ lg: 's4', md: 's3' }}
                align={{ lg: 'left', md: 'center' }}
              />
              <Typography
                tx="reach:details.description"
                color="white"
                align={{ lg: 'left', md: 'center' }}
              />
            </div>

            {renderColorsInfo({ lg: false, md: true })}
          </StackedContainer>
        </Cell>

        <Cell hidden={{ lg: true, md: false }}>{renderColorsInfo(false)}</Cell>
      </Grid>

      <StackedContainer
        verticalPadding="unset"
        horizontalPadding={{ lg: '8.33%', md: 's3', sm: 's1' }}
        rowGap={{ lg: 's5', md: 's2' }}>
        <Typography
          tx="reach:details.comparison.title"
          color="white"
          variant="h5"
          align="center"
        />

        <BoxContainer hidden={{ lg: false, md: true }}>
          <ImageContainer
            src="pages/reach/desk-big.png"
            aspectRatio="1:0.38"
            backgroundColor="#bf946b"
            objectFit="contain">
            <FlexContainer justifyContent="space-between">
              <div>
                <Typography
                  tx="reach:details.comparison.palm.title"
                  color="sunrise"
                />

                <Typography
                  tx="reach:details.comparison.palm.description"
                  color="white"
                  maxWidth="20rem"
                />
              </div>

              <div>
                <Typography
                  tx="reach:details.comparison.traditional.title"
                  color="sunrise"
                />

                <Typography
                  tx="reach:details.comparison.traditional.description"
                  color="white"
                  maxWidth="20rem"
                />
              </div>
            </FlexContainer>
          </ImageContainer>
        </BoxContainer>

        <BoxContainer hidden={{ lg: true, md: false }}>
          <Tabs
            config={tabsConfig}
            headingWidth="max-width"
            headingAlign="center"
            headingGap={30}
            headingTypographyProps={{ variant: 'p3', textStyle: 'bold' }}
            headingActiveColor="white"
            headingColor="translucentWhite"
            headerBorderVisible={false}
          />
        </BoxContainer>
      </StackedContainer>
    </StackedContainer>
  );
};
