import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import { formatDate } from 'utils/date';

const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];

export interface UseDatePickerProps {
  name: string;
  value: dayjs.ConfigType;
  allowFutureDates?: boolean;
  allowPastDates?: boolean;
  onChange: (event: any) => void;
  onDateChange: (value: dayjs.ConfigType) => void;
}

export const useDatePicker = (props: UseDatePickerProps) => {
  const {
    name,
    value,
    allowFutureDates,
    allowPastDates,
    onDateChange,
    onChange,
  } = props;

  const [activeMonth, setActiveMonth] = useState(new Date());

  const [opened, setOpened] = useState(false);
  const toggleOpened = useCallback(() => setOpened(!opened), [
    opened,
    setOpened,
  ]);
  const showPopup = useCallback(() => setOpened(true), [setOpened]);

  const hidePopup = useCallback(() => setOpened(false), [setOpened]);

  const getDaysInMonthArray = useCallback(() => {
    const days = dayjs(activeMonth).daysInMonth();
    const weekday = dayjs(activeMonth).set('date', 1).day();

    const emptyDays = Array.from(
      { length: weekday },
      (_, index) => (index + 1) * -1,
    );

    return emptyDays.concat(
      Array.from({ length: days }, (_, index) => index + 1),
    );
  }, [activeMonth]);

  const isDateSelected = useCallback(
    (day: number) => {
      if (day > 0) {
        return formatDate(dayjs(activeMonth).date(day)) === formatDate(value);
      }

      return false;
    },
    [activeMonth, value],
  );

  const isDateActive = useCallback(
    (day: number) => {
      const selectedDate = dayjs(activeMonth).date(day);

      if (!allowFutureDates) {
        return (
          formatDate(selectedDate, 'YYYY MM DD') <=
          formatDate(new Date(), 'YYYY MM DD')
        );
      }

      if (!allowPastDates) {
        return (
          formatDate(selectedDate, 'YYYY MM DD') >=
          formatDate(dayjs().add(1, 'day'), 'YYYY MM DD')
        );
      }

      return true;
    },
    [value, activeMonth, allowFutureDates, allowPastDates],
  );

  const selectDate = useCallback(
    (day: number, hideDialog: boolean = false) => {
      if (onDateChange) {
        onDateChange(dayjs(activeMonth).date(day));
      }

      if (onChange) {
        onChange({
          target: {
            name,
            value: formatDate(dayjs(activeMonth).date(day), 'MM/DD/YYYY'),
          },
        });
      }

      if (hideDialog) {
        hidePopup();
      }
    },
    [name, activeMonth, allowFutureDates, onDateChange, onChange, hidePopup],
  );

  const previous = useCallback(() => {
    setActiveMonth(dayjs(activeMonth).add(-1, 'month').toDate());
  }, [activeMonth, setActiveMonth]);

  const next = useCallback(() => {
    setActiveMonth(dayjs(activeMonth).add(1, 'month').toDate());
  }, [activeMonth, setActiveMonth]);

  return {
    activeMonth,
    daysInMonth: getDaysInMonthArray(),
    days,
    isDateSelected,
    isDateActive,
    selectDate,
    previous,
    next,
    opened,
    toggleOpened,
    showPopup,
  };
};
