import React from 'react';
import { ColoredContainerProps } from './colored-section.props';
import { ColoredContainerPresets } from './colored-section.presets';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';

export const ColoredContainer = (props: ColoredContainerProps) => {
  const {
    title,
    titleTx,
    description,
    descriptionTx,
    descriptionComponent,
    titleColor,
    titleHidden,
    titleMaxWidth,
    descriptionColor,
    descriptionHidden,
    titleVariant,
    descriptionVariant,
    gap,
    extraRowContent,
    backgroundColor,
    titleAlign,
    descriptionAlign,
    titleTextStyle,
    descriptionTextStyle,
    maxWidth,
    padding,
    horizontalPadding,
    verticalPadding,
    paddingTop,
    paddingBottom,
    paddingLeft,
    paddingRight,
    hidden,
    ...others
  } = Object.assign({}, ColoredContainerPresets, props);

  const rowsCount =
    (title || titleTx ? 1 : 0) +
    (description || descriptionTx ? 1 : 0) +
    (extraRowContent ? 1 : 0);

  return (
    <FlexContainer
      backgroundColor={backgroundColor}
      justifyContent="center"
      padding={padding}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
      hidden={hidden}
      {...others}>
      <Grid rows={rowsCount} rowGap={gap} padding="unset" maxWidth={maxWidth}>
        {title ||
          (titleTx && (
            <Cell hidden={titleHidden}>
              <Typography
                tx={titleTx}
                text={title}
                color={titleColor}
                variant={titleVariant}
                align={titleAlign}
                textStyle={titleTextStyle}
                maxWidth={titleMaxWidth}
              />
            </Cell>
          ))}
        <Cell hidden={descriptionHidden}>
          {descriptionComponent ? (
            descriptionComponent
          ) : (
            <Typography
              type="p"
              tx={descriptionTx}
              text={description}
              color={descriptionColor}
              variant={descriptionVariant}
              align={descriptionAlign}
              textStyle={descriptionTextStyle}
              whiteSpace="pre-wrap"
            />
          )}
        </Cell>
        {extraRowContent && <Cell>{extraRowContent}</Cell>}
      </Grid>
    </FlexContainer>
  );
};
