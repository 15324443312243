import { StyledCartSection } from 'modules/shop/cart/cart.styles';
import React from 'react';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon/icon';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { GiftCardsBlockProps } from './gift-cards-block.props';
import { GiftCardsBlockPresets } from './gift-cards-block.presets';
import { formatDate } from 'utils/date';
import { GiftCardRow } from 'layout/gift-card-row';
import { GiftCardRecord } from 'types/gift-card.type';

export const GiftCardsBlock = (props: GiftCardsBlockProps) => {
  const {
    records,
    groupDate,
    padded,
    status,
    onRemove,
    canRemove,
  } = Object.assign({}, GiftCardsBlockPresets, props);

  return (
    <StyledCartSection padded={padded}>
      <TypographyWithIcon
        tx="cart:digitalGiftCard"
        gap="3px"
        color="primary"
        textStyle="bold"
        icon={<Icon name="gift-card" width="1.9rem" height="1.6rem" />}
      />

      <Typography
        type="h2"
        variant="p1"
        textStyle="bold"
        tx="cart:sendOn"
        txValues={{ date: formatDate(groupDate, 'MMMM DD') }}
      />

      {!!status && <Typography color="grey" text={status} />}

      <StackedContainer
        gap={{ lg: 's3', md: 's2' }}
        padding="unset"
        marginTop="s3">
        {records.map((record: GiftCardRecord, index: number) => (
          <GiftCardRow
            key={index}
            data={record}
            removable={canRemove}
            onRemove={onRemove}
          />
        ))}
      </StackedContainer>
    </StyledCartSection>
  );
};
