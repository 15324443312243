import React, { useEffect, useState } from 'react';
import {
  StyledContainer,
  StyledGiftCodeInput,
  StyledGiftCodeOption,
  StyledGiftCodeOptionsContainer,
} from 'core/gift-code-input/gift-code-input.styles';
import { Button } from 'core/button';
import { TextInput } from 'core/text-input';
import { GiftCodeInputProps } from 'core/gift-code-input/gift-code-input.props';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon/icon';

export const GiftCodeInput = (props: GiftCodeInputProps) => {
  const { value, onApply } = props;
  const [active, setActive] = useState(false);
  const [innerValue, setInnerValue] = useState<number | string>('');

  useEffect(() => {
    setInnerValue(value);
  }, [value]);

  const toggleActive = () => {
    setActive(!active);
  };

  const onCodeApply = () => {
    if (innerValue) {
      toggleActive();

      if (onApply) {
        onApply(String(innerValue));

        setInnerValue('');
      }
    }
  };

  const onCancel = () => {
    setInnerValue('');
    setActive(false);
  };

  const onRemove = () => {
    onCancel();

    if (onApply) {
      onApply('');
    }
  };

  return (
    <StyledGiftCodeInput>
      <StyledContainer active={!active && !innerValue}>
        <Button
          variant="action"
          tx="common.giftCode"
          icon={<Icon name="plus-circle-bold" color="primary" />}
          iconLocation="left"
          onClick={toggleActive}
        />
      </StyledContainer>

      <StyledContainer active={active}>
        {active && (
          <TextInput
            value={innerValue}
            placeholderTx="common.giftCodePlaceholder"
            clearable={false}
            autoFocus={true}
            onTextChange={setInnerValue}
            onEnter={onCodeApply}
          />
        )}

        <StyledGiftCodeOptionsContainer>
          <StyledGiftCodeOption
            tx="common.applyBtn"
            variant="p3"
            color={!!innerValue ? 'primary' : 'lightGrey'}
            onClick={onCodeApply}
          />

          <StyledGiftCodeOption
            tx="common.cancelBtn"
            variant="p3"
            color="grey"
            onClick={onCancel}
          />
        </StyledGiftCodeOptionsContainer>
      </StyledContainer>

      <StyledContainer active={!active && !!innerValue}>
        <Typography
          type="span"
          text={innerValue}
          color="grey"
          onClick={onCancel}
        />{' '}
        <Typography
          type="span"
          tx="common.remove"
          color="red"
          variant="p3"
          textStyle="bold"
          onClick={onRemove}
        />
      </StyledContainer>
    </StyledGiftCodeInput>
  );
};
