import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Spacer } from 'layout/spacer';
import { useModalClose } from 'context/modal.context';
import { useAuth } from 'context/auth.context';

export const EnjoyGiftModal = () => {
  const [{ user }] = useAuth();
  const { closeLastOpened } = useModalClose();

  return (
    <StackedContainer
      verticalPadding="s5"
      justifyContent="center"
      justifyItems="center">
      <Icon name="gift-teal" color="primary" size="4.8rem" />

      <Typography
        text={`Enjoy your gift, ${user?.firstName}!`}
        variant="h4"
        align="center"
      />

      <Typography
        text="Thanks for signing up. We’ve credited your account $20, which will be automatically applied to your first purchase."
        variant="p3"
        color="grey"
        align="center"
      />

      <Spacer height="s2" />

      <StackedContainer width="100%" padding="unset">
        <Button
          text="Let's Shop"
          linkHref="/shop"
          width="100%"
          onClick={closeLastOpened}
        />
      </StackedContainer>
    </StackedContainer>
  );
};
