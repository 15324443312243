import React from 'react';
import { Grid } from 'layout/grid';
import { SKU } from 'types/product.type';
import { Cell } from 'layout/cell';
import { ProductCard } from 'layout/product-card';

interface ProductsSectionProps {
  skus: SKU[];
}

export const ProductsSection = ({ skus }: ProductsSectionProps) => {
  return (
    <Grid
      columns={{ lg: 4, md: 3, sm: 2 }}
      verticalPadding={{ lg: 's6', md: 's5' }}>
      {skus.map((item: SKU) => (
        <Cell key={item.sku}>
          <ProductCard product={item} />
        </Cell>
      ))}
    </Grid>
  );
};
