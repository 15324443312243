import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { QuotesIcon } from 'layout/common';
import { useTheme } from 'styled-components';

export const QuoteSection = ({ quote, highlightColor }) => {
  const theme = useTheme();

  return (
    <StackedContainer
      backgroundColor={highlightColor}
      justifyContent="center"
      justifyItems="center"
      rowGap="s2"
      horizontalPadding="s3"
      verticalPadding={{ lg: 's6', md: 's5' }}>
      <QuotesIcon color={theme.colors.white} />

      <Typography
        variant="h2"
        type="p"
        textStyle="regular"
        text={quote}
        maxWidth={{ lg: '67rem', md: 'unset' }}
        color="white"
        align="center"
      />
    </StackedContainer>
  );
};
