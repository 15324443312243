import React from 'react';
import { GetStaticPaths, GetStaticProps } from 'next';
import { api } from 'services/api';
import { Designer } from 'types/designer.type';
import { DesignerPage } from 'modules/shop';
import { DesignerLayout } from 'layout/layout';

export const getStaticPaths: GetStaticPaths = async () => {
  const { data, success } = await api.designer.getAll<{
    designers: Designer[];
    success: boolean;
  }>();

  return {
    paths: success
      ? data.designers
          .filter(
            designer =>
              designer?.profileLink &&
              !['ammunition', 'reach', 'sprk'].includes(designer?.profileLink),
          )
          .map(({ profileLink }) => ({
            params: {
              designerProfileLink: profileLink,
            },
          }))
      : [],
    fallback: true,
  };
};

export const getStaticProps: GetStaticProps = async ({
  params: { designerProfileLink },
}) => {
  try {
    const {
      data: { data },
      success,
    } = await api.designer.getInfo(String(designerProfileLink));

    const records = data?.splitProducts
      ? data.splitProducts.map(product => {
          if (!product.photoUrls.photos) {
            product.photoUrls.photos = {
              product: [],
              lifestyle: [],
              lifestyleset: [],
              scale: [],
            };
          }

          const photos = product.photoUrls.photos;
          product.photoUrls.photos.product = photos.product || [];
          product.photoUrls.photos.lifestyle = photos.lifestyle || [];
          product.photoUrls.photos.lifestyleset = photos.lifestyleset || [];

          return product;
        })
      : [];

    return {
      props: {
        current: data?.designer || {},
        records,
        notFound: !success,
        layoutState: {
          editingDesigner: false,
          designerBackgroundColor:
            data?.designer?.theme?.background || '#f4f4f4',
          designerHighlightColor: data?.designer?.theme?.highlight || '#1b7b76',
        },
        productState: {
          records,
        },
      },
      revalidate: 1,
    };
  } catch (e) {
    throw new Error(
      '[designerProfileLink/index]: Error connecting to porter api',
    );
  }
};

DesignerPage['Layout'] = DesignerLayout;

export default DesignerPage;
