import { StyledCartSection } from 'modules/shop/cart/cart.styles';
import React from 'react';
import { ShipmentsBlockProps } from 'modules/shop/cart/sections/shipments-block/shipments-block.props';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { ShipmentRow } from 'layout/shipment-row';
import { CartProduct } from 'services/api/cart/cart-api.types';
import { formatUtcDate } from 'utils/date';
import { Tooltip } from 'core/tooltip';
import { Icon } from 'core/icon/icon';
import { ShipmentsBlockPresets } from 'modules/shop/cart/sections/shipments-block/shipments-block.presets';
import { TypographyWithIcon } from 'core/typography-with-icon';

export const ShipmentsBlock = (props: ShipmentsBlockProps) => {
  const {
    records,
    inStock,
    leadTime,
    canRemove,
    canEditGiftInfo,
    giftable,
    canEnterGiftMessage,
    canNavigateToProduct,
    onRemove,
    onIsGiftChange,
    onGiftMessageChange,
  } = Object.assign({}, ShipmentsBlockPresets, props);

  return (
    <StyledCartSection padded={true}>
      {inStock && (
        <TypographyWithIcon
          tx="common.quickShip"
          gap="3px"
          color="primary"
          textStyle="bold"
          icon={
            <Icon name="ray" color="primary" width="1.3rem" height="1.8rem" />
          }
        />
      )}

      <TypographyWithIcon
        type="h2"
        variant="p1"
        textStyle="bold"
        tx="cart:estimateDeliveryBy"
        txValues={{
          date: formatUtcDate(
            inStock
              ? leadTime?.estInStockDeliveryBySec
              : leadTime?.estDeliveryBySec,
            'MMM DD',
          ),
        }}
        icon={
          !inStock && (
            <Tooltip
              content={
                <Typography
                  tx="common.madeForYouDescription"
                  color="grey"
                  variant="p3"
                />
              }
              roundContainer={true}>
              <Icon name="help" size="2rem" color="grey" />
            </Tooltip>
          )
        }
        iconPosition="right"
      />

      <StackedContainer gap="s2" padding="unset" marginTop="s3">
        {records.map((record: CartProduct, index: number) => (
          <ShipmentRow
            key={index}
            data={record}
            stocksCount={record.product?.stocks}
            removable={canRemove}
            canEditGiftInfo={canEditGiftInfo}
            giftable={giftable}
            canEnterGiftMessage={canEnterGiftMessage}
            canNavigateToProduct={canNavigateToProduct}
            isGift={record.isGift}
            onRemove={(product: CartProduct) => onRemove(product, index)}
            onIsGiftChange={(value: boolean) =>
              onIsGiftChange(value, record.cartId)
            }
            onGiftMessageChange={(value: string) => {
              if (onGiftMessageChange) {
                onGiftMessageChange(value, record.cartId);
              }
            }}
          />
        ))}
      </StackedContainer>
    </StyledCartSection>
  );
};
