import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { Icon } from 'core/icon/icon';

export const StyledShipmentRow = styled.div`
  ${({ theme }) => css`
    display: grid;
    grid-template-columns: 8rem 1fr;
    gap: ${theme.spacing.s2};
    user-select: none;

    ${media.lessThan('sm')`
      grid-template-columns: 6rem 1fr;
      align-items: start;
    `}
  `}
`;

export const StyledSpecsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const StyledRightAlignedBlock = styled.div`
  text-align: right;
  position: relative;

  ${media.lessThan('sm')`
    display: none;
  `}
`;

export const StyledCloseButton = styled(Icon)`
  position: relative;
  cursor: pointer;
  top: 6px;
`;

export const StyledGiftMessageContainer = styled.div`
  display: grid;
  row-gap: 1rem;
  margin-top: 2rem;
`;

export const StyledGiftHeader = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  column-gap: 0.8rem;
  padding-bottom: 0.8rem;
`;

export const StyledRowContent = styled.div<{ removable: boolean }>`
  ${({ removable, theme }) => css`
    display: grid;
    height: max-content;
    grid-template-columns: 1fr max-content ${removable ? 'max-content' : ''};
    column-gap: ${theme.spacing.s1};
  `}
`;
