import styled from 'styled-components';
import { media } from 'styles/media';

export const StyledShippingBannerSmaller = styled.div`
  display: flex;
  justify-content: space-between;

  p {
    min-width: 11rem;
    max-width: 12rem;
  }
`;

export const StyledShippingBlock = styled.div`
  display: grid;
  grid-template-columns: max-content min-content;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.8rem;

  ${media.lessThan('md')`
     grid-template-columns: 1fr;
     justify-items: start;
  `}
`;
