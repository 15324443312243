import React from 'react';
import giftCardsLocale from '../../../../locales/en/gift-cards.json';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';

const questions = Object.keys(giftCardsLocale.questions);

export const QuestionsSection = () => {
  return (
    <StackedContainer
      horizontalPadding={{ lg: '25%', md: 's3', sm: 's1' }}
      verticalPadding={{ lg: 's6', md: 's5' }}
      backgroundColor="cream"
      justifyItems="center"
      gap={{ lg: 's5', md: 's4' }}>
      <Typography
        tx="giftCards:frequentQuestions"
        variant="h2"
        align="center"
      />

      <StackedContainer padding="unset" gap="s2" maxWidth="67.5rem">
        {questions.map((questionKey: string) => (
          <div key={questionKey}>
            <Typography
              tx={`giftCards:questions.${questionKey}.question`}
              textStyle="bold"
            />
            <Typography
              tx={`giftCards:questions.${questionKey}.answer`}
              color="grey"
            />
          </div>
        ))}
      </StackedContainer>
    </StackedContainer>
  );
};
