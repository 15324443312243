import React from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Designer } from 'types/designer.type';
import { Thumbnail } from 'core/thumbnail';

export const awardImages = {
  adesign: 'pages/product/awards/award-adesign.png',
  cleverest: 'pages/product/awards/award-cleverest.png',
  'communication-arts': 'pages/product/awards/award-communication-arts.png',
  'ces-innovations': 'pages/product/awards/award-ces-innovations.png',
  'cooper-hewitt': 'pages/product/awards/award-cooper-hewitt.png',
  core77: 'pages/product/awards/award-core77.png',
  dad: 'pages/product/awards/award-dad.png',
  'fast-innovative': 'pages/product/awards/award-fast-innovative.png',
  'good-design': 'pages/product/awards/award-good-design.png',
  ida: 'pages/product/awards/award-ida.png',
  idea: 'pages/product/awards/award-idea.png',
  idsa: 'pages/product/awards/award-idsa.png',
  if: 'pages/product/awards/award-if.png',
  'red-dot': 'pages/product/awards/award-red-dot.png',
  spark: 'pages/product/awards/award-spark.png',
  'time-best': 'pages/product/awards/award-time-best.png',
};

const clampText = text => {
  if (!text) {
    return '';
  }
  const firstParagraph = (text || '').split('\n')[0];

  if (firstParagraph.length < 250) {
    return firstParagraph;
  }

  let sanitizedBio = firstParagraph.slice(0, 250);

  if (sanitizedBio[sanitizedBio.length - 1] === '.') {
    sanitizedBio = sanitizedBio.slice(0, sanitizedBio.length - 1);
  }

  return `${sanitizedBio}...`;
};

export interface DesignerSectionProps {
  data: Designer;
  hasTopPadding: boolean;
}

export const DesignerSection = (props: DesignerSectionProps) => {
  const { data, hasTopPadding } = props;

  const getAwardBlock = award => {
    return (
      <StackedContainer key={award.code} gap="s1" flow="column" padding="unset">
        <ImageContainer
          src={awardImages[award.code]}
          width="3.2rem"
          height="3.2rem"
          backgroundColor="transparent"
        />
        <Typography variant="p3" text={award.award} color="grey" />
      </StackedContainer>
    );
  };

  return (
    <Grid
      columns={{ lg: '1fr 4.5fr 1fr 4.5fr 1fr', md: '1fr' }}
      verticalPadding={{ lg: 's6', sm: 's5' }}
      paddingTop={{ lg: 's6', md: hasTopPadding ? 's5' : 'unset' }}
      gap="unset"
      rowGap={{ lg: 's5', sm: 's4' }}>
      <Cell column={{ lg: 2, md: 1 }}>
        <ImageContainer
          src={data.photoUrl}
          source="external"
          aspectRatio="1:1"
          alt={data.name}
        />
      </Cell>
      <Cell column={{ lg: 4, md: 1 }}>
        <StackedContainer
          gap={{ lg: 's4', sm: 's3' }}
          alignContent="center"
          padding="unset">
          <StackedContainer
            gap="s2"
            columns={{ lg: '8rem 1fr', sm: '6.5rem 1fr' }}
            padding="unset">
            <Thumbnail
              src={data.logoUrl}
              size={{ lg: '8rem', sm: '6.5rem' }}
              alt={`${data.name} Logo`}
            />

            <div>
              <Typography variant="h3" text={data.name} />
              <Typography variant="p2" text={data.title} />
              <Typography
                variant="p2"
                text={
                  data.country === 'US'
                    ? `${data.city}, ${data.state}`
                    : `${data.city}, ${data.countryName}`
                }
                color="grey"
              />
            </div>
          </StackedContainer>

          <Grid
            columns={{ lg: 'repeat(3, max-content)', sm: '1fr' }}
            columnGap="s2"
            rowGap="s1"
            padding="unset">
            {data.awards.slice(0, 3).map(award => getAwardBlock(award))}
          </Grid>

          <StackedContainer gap="s2" padding="unset">
            <Typography text={clampText(data.bio)} color="grey" />
            <Typography
              text="Learn more"
              variant="link"
              color="primary"
              href={`/${data.profileLink}`}
              linkAnalyticsEvent="Product To Designer"
            />
          </StackedContainer>
        </StackedContainer>
      </Cell>
    </Grid>
  );
};
