import styled, { css } from 'styled-components';
import { Typography } from 'core/typography';

export const StyledGiftCodeInput = styled.div`
  & > * {
    position: absolute;
  }
`;

export const StyledContainer = styled.div<{ active: boolean }>`
  ${({ active }) => css`
    opacity: ${active ? 1 : 0};
    display: ${active ? 'block' : 'none'};
    position: relative;
  `}
`;

export const StyledGiftCodeOptionsContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 3px;
  cursor: pointer;
`;

export const StyledGiftCodeOption = styled(Typography)`
  ${({ theme }) => css`
    &:first-of-type {
      padding-right: 0.6rem;
      position: relative;

      &:after {
        content: '|';
        position: absolute;
        right: 0;
        color: ${theme.colors.lightGrey};
      }
    }

    &:last-child {
      padding-left: 0.5rem;
    }
  `}
`;
