import styled, { css } from 'styled-components';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { media } from 'styles/media';

export const StyledDesignItem = styled.div<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    border: 1px solid ${selected ? theme.colors.primary : theme.colors.paper};
    border-radius: ${theme.borderRadius};
    padding: ${theme.spacing.s2};
    cursor: pointer;
    display: grid;
    grid-template-columns: 12rem 1fr;
    align-items: center;
    align-content: center;
    column-gap: ${theme.spacing.s2};

    ${media.lessThan('md')`    
        grid-template-columns: 8rem 1fr;
    `}

    ${StyledCard} {
      border-radius: 6px;
      box-shadow: unset;
    }
  `}
`;
