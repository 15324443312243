import React, { useMemo } from 'react';
import { DatePickerProps } from './date-picker.props';
import { DatePickerPresets } from './date-picker.presets';
import { TextInput } from 'core/text-input';
import { formatDate } from 'utils/date';
import {
  StyledCalendarIcon,
  StyledDatePickerContainer,
  StyledDatePickerPopup,
  StyledDay,
  StyledPopupDivider,
} from './date-picker.styles';
import { useDatePicker } from 'core/date-picker/date-picker.hooks';
import { Grid } from 'layout/grid';
import { Typography } from 'core/typography';
import { FlexContainer } from 'layout/flex-container';
import { Icon } from 'core/icon';

export const DatePicker = (props: DatePickerProps) => {
  const {
    name,
    value,
    displayFormat,
    allowPastDates,
    allowFutureDates,
    onChange,
    onDateChange,
    ...other
  } = Object.assign({}, DatePickerPresets, props);

  const {
    activeMonth,
    daysInMonth,
    days,
    isDateSelected,
    isDateActive,
    opened,
    toggleOpened,
    showPopup,
    selectDate,
    previous,
    next,
  } = useDatePicker({
    name,
    value,
    allowPastDates,
    allowFutureDates,
    onChange,
    onDateChange,
  });

  const innerValue = useMemo(() => formatDate(value, displayFormat), [
    value,
    displayFormat,
  ]);

  return (
    <StyledDatePickerContainer>
      <TextInput
        name={name}
        value={innerValue}
        clearable={false}
        readonly={true}
        onFocus={showPopup}
        {...other}
      />

      <StyledCalendarIcon name="calendar" size="2rem" />

      {opened && (
        <StyledDatePickerPopup visible={true} onClickOutside={toggleOpened}>
          <FlexContainer justifyContent="space-between" marginBottom="s2">
            <Icon
              name="previous"
              size="2.4rem"
              cursor="pointer"
              onClick={previous}
            />
            <Typography
              text={formatDate(activeMonth, 'MMMM YYYY')}
              textStyle="bold"
            />
            <Icon name="next" size="2.4rem" cursor="pointer" onClick={next} />
          </FlexContainer>

          <StyledPopupDivider />

          <Grid padding="unset" columns={7} gap={{ lg: '8px', sm: '6px' }}>
            {days.map((day: string) => (
              <StyledDay key={day}>
                <Typography
                  text={day}
                  variant="p3"
                  color="grey"
                  align="center"
                />
              </StyledDay>
            ))}

            {daysInMonth.map((day: number) => (
              <StyledDay
                key={day}
                selected={isDateSelected(day)}
                active={isDateActive(day)}
                onClick={() => selectDate(day, true)}>
                <Typography
                  text={day > 0 ? day : ''}
                  variant="p3"
                  align="center"
                  color={isDateSelected(day) ? 'white' : 'black'}
                />
              </StyledDay>
            ))}
          </Grid>
        </StyledDatePickerPopup>
      )}
    </StyledDatePickerContainer>
  );
};
