import React from 'react';
import { ProfileLinks } from 'types/user.type';
import { StackedContainer } from 'layout/stacked-container';
import { ImageContainer } from 'layout/image-container';
import { Icon } from 'core/icon';
import { Button } from 'core/button';
import { useLayout } from 'context/layout.context';

export interface SocialSectionProps {
  photoUrl: string;
  links: ProfileLinks;
  alt: string;
  backgroundColor: string;
}

export const SocialSection = (props: SocialSectionProps) => {
  const { alt, links, photoUrl, backgroundColor } = props;

  return (
    <StackedContainer
      padding="unset"
      backgroundColor={backgroundColor}
      justifyContent="center"
      justifyItems="center"
      rowGap={{ lg: 's5', md: 's4' }}
      verticalPadding={{ lg: 's6', md: 's5' }}>
      <ImageContainer
        src={photoUrl}
        source="external"
        width={{ lg: '32rem', md: '16rem' }}
        height={{ lg: '32rem', md: '16rem' }}
        alt={alt}
        roundContainer={true}
      />

      <StackedContainer
        padding="unset"
        flow="column"
        justifyItems="center"
        justifyContent="center"
        columnGap={{ lg: 's5', md: 's4' }}>
        {!!links?.website && (
          <Button
            linkHref={`http://${links.website}`}
            linkTarget="_blank"
            variant="action"
            analyticsEvent="Designer Link Website"
            icon={<Icon name="world" size="2rem" color="black" />}
          />
        )}

        {!!links?.instagram && (
          <Button
            linkHref={`https://www.instagram.com/${links.instagram}`}
            linkTarget="_blank"
            variant="action"
            analyticsEvent="Designer Link Instagram"
            icon={<Icon name="instagram" size="2rem" color="black" />}
          />
        )}

        {!!links?.behance && (
          <Button
            linkHref={`https://www.behance.net/${links.behance}`}
            linkTarget="_blank"
            variant="action"
            analyticsEvent="Designer Link Behance"
            icon={<Icon name="behance" size="2rem" color="black" />}
          />
        )}

        {!!links?.twitter && (
          <Button
            linkHref={`https://www.twitter.com/${links.twitter}`}
            linkTarget="_blank"
            variant="action"
            analyticsEvent="Designer Link Twitter"
            icon={<Icon name="twitter" size="2rem" color="black" />}
          />
        )}
      </StackedContainer>
    </StackedContainer>
  );
};
