import styled, { css } from 'styled-components';
import { media } from 'styles/media';
import { StyledAffirmInfo } from 'layout/common';

export const StyledCartSummaryTitle = styled.div`
  ${({ theme }) => css`
    margin-bottom: ${theme.spacing.s1};
  `}
`;

export const StyledSummaryRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.5rem;
`;

export const StyledSummaryAffirmInfo = styled(StyledAffirmInfo)`
  ${({ theme }) => css`
    text-align: right;
    margin-top: ${theme.spacing.s3};

    ${media.lessThan('sm')`
      margin-top: ${theme.spacing.s2};
    `}
  `}
`;
