import React from 'react';
import { Page } from 'core/page';
import {
  AboutSection,
  DesignerSection,
  DesignSection,
  DetailsSection,
  HeroSection,
  InfoSection,
  IntroductionSection,
  PreviewSection,
  ProductsSection,
  SecondDesignerSection,
  ShopSection,
  SizingSection,
} from 'modules/shop/reach/sections';
import { useProduct } from 'context/product.context';
import { ReachPageProps } from 'modules/shop/reach/reach.props';
import { Reviews } from 'layout/reviews';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ThemeProvider } from 'styled-components/macro';
import { reachTheme } from 'modules/shop/reach/reach.theme';
import { Icon } from 'core/icon';
import { InstagramSection } from 'layout/instagram-section';

const instagramImages = [
  'pages/reach/insta-1.jpg',
  'pages/reach/insta-2.jpg',
  'pages/reach/insta-3.jpg',
  'pages/reach/insta-4.jpg',
  'pages/reach/insta-5.jpg',
  'pages/reach/insta-6.jpg',
];

export const ReachPage = (props: ReachPageProps) => {
  const [{ records, reviewsData }] = useProduct();

  const title = `Reach - ${props.designerData.title || ''} in ${
    props.designerData.city || ''
  }, ${props.designerData.state} | Gantri ®`;

  return (
    <ThemeProvider theme={reachTheme}>
      <Page
        headerLocked={true}
        hero={<HeroSection />}
        seoConfig={{
          title,
          description: props.designerData.bio,
          openGraph: {
            title,
            description: props.designerData.bio,
            images: [
              {
                url: props.designerData.photoUrl,
                alt: 'Reach, San Francisco, California',
              },
            ],
          },
        }}>
        <IntroductionSection />
        <InfoSection />
        <PreviewSection skus={records} />
        <DesignSection />
        <ProductsSection records={records} />
        <DesignerSection />
        <DetailsSection />
        <SecondDesignerSection />
        <SizingSection />
        <ShopSection skus={props.skus} />

        {!!reviewsData?.reviews?.length && (
          <Grid
            backgroundColor="#ded9d4"
            columns={{ lg: '3fr 6fr 3fr', md: '1fr' }}
            verticalPadding={{ lg: 's6', md: 's5' }}>
            <Cell column={{ lg: 2, md: 1 }}>
              <Reviews
                reviews={reviewsData.reviews || []}
                total={reviewsData.total || 0}
                color="opaqueGreen"
                textColor="greyGreen"
              />
            </Cell>
          </Grid>
        )}

        <InstagramSection
          logo={<Icon name="reach" size="4.8rem" />}
          name="reach_lighting"
          backgroundColor="#abbfc1"
          buttonTextColor="opaqueGreen"
          images={instagramImages}
        />
        <AboutSection />
      </Page>
    </ThemeProvider>
  );
};
