import React from 'react';
import { Hero } from 'layout/hero';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Icon } from 'core/icon';

export const HeroSection = () => (
  <Hero
    height={{
      lg: `calc(100vh - 18rem)`,
      md: '100vw',
    }}
    backgroundImageSrc={{
      lg: 'pages/reach/hero.jpg',
      sm: 'pages/reach/hero-small.jpg',
    }}
    backgroundObjectPosition="100% 0"
    scrollable={false}
    content={
      <StackedContainer
        justifyContent="center"
        justifyItems="center"
        alignContent="space-between"
        paddingTop="10%"
        paddingBottom="s5"
        hidden={{ lg: false, md: true }}>
        <StackedContainer
          padding="unset"
          alignContent="center"
          justifyContent="center"
          justifyItems="center">
          <Typography
            tx="reach:hero.introduction"
            color="white"
            variant="h5"
            fontSize="1.4rem"
          />
          <Typography
            tx="reach:hero.title"
            variant="h1"
            color="white"
            fontSize="6rem"
            lineHeight="8rem"
            textStyle="regular"
          />
          <Typography
            tx="reach:hero.description"
            variant="h3"
            color="white"
            textStyle="regular"
          />
        </StackedContainer>

        <Icon name="reach-logo" width="20rem" height="6.6rem" />
      </StackedContainer>
    }
  />
);
