import React from 'react';
import { Grid } from 'layout/grid';
import { ImageContainer } from 'layout/image-container';
import range from 'lodash/range';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';

const steps = range(1, 5);

export const HowItWorksSection = () => {
  return (
    <StackedContainer
      gap={{ lg: 's5', md: 's4' }}
      verticalPadding={{ lg: 's6', md: 's5' }}
      justifyItems="center">
      <Typography tx="giftCards:howItWorks.title" variant="h2" align="center" />

      <Grid
        padding="unset"
        rowGap="s3"
        columnGap="unset"
        columns={{ lg: 'repeat(4, 24rem)', md: '1fr' }}
        justifyContent="space-between"
        justifyItems="center"
        width="100%"
        maxWidth="114rem">
        {steps.map((step: number) => (
          <StackedContainer
            key={step}
            padding="unset"
            justifyItems="center"
            gap="s1"
            maxWidth="28rem">
            <ImageContainer
              backgroundColor="unset"
              src={`pages/gift-cards/works${step}.png`}
              width={{ lg: '12rem', md: '8rem' }}
              height={{ lg: '12rem', md: '8rem' }}
            />

            <Typography
              tx={`giftCards:howItWorks.steps.step${step}.title`}
              variant="h3"
              textStyle="regular"
              align="center"
            />
            <Typography
              tx={`giftCards:howItWorks.steps.step${step}.description`}
              color="grey"
              align="center"
            />
          </StackedContainer>
        ))}
      </Grid>
    </StackedContainer>
  );
};
