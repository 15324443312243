import styled, { css } from 'styled-components';
import { animated } from '@react-spring/web';

export const StyledListScroll = styled.div<{ visible: boolean }>`
  ${({ theme, visible }) => css`
    height: 1px;
    background-color: ${theme.colors.lightGrey};
    position: relative;
    display: ${visible ? 'block' : 'none'};
    margin-top: 3rem;
  `}
`;

export const StyledScrollIndicator = styled(animated.div)`
  ${({ theme }) => css`
    height: 3px;
    background-color: ${theme.colors.black};
    border-radius: 1.5px;
    width: 50px;
    position: absolute;
    top: -1px;
  `}
`;
