import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ListScrollProps } from 'layout/list-scroll/list-scroll.props';
import { StyledListScroll, StyledScrollIndicator } from './list-scroll.styles';
import { useSpring } from 'react-spring';
import useResizeObserver from 'use-resize-observer/polyfilled';
import { useMediaLarge } from 'utils/hooks';

export const ListScroll = (props: ListScrollProps) => {
  const { itemsCount, listRef, scrollData } = props;
  const [clientWidth, setClientWidth] = useState(0);
  const containerRef = useRef();
  const large = useMediaLarge();

  const visible = useMemo(() => {
    return scrollData?.totalPages >= 1;
  }, [scrollData, clientWidth]);

  useEffect(() => {
    setClientWidth(containerRef.current?.clientWidth || 0);
  }, [containerRef.current]);

  const indicatorWidth = useMemo(() => {
    if (!large) {
      return 150;
    }

    if (scrollData) {
      return clientWidth / (scrollData.totalPages + 1);
    }

    return clientWidth / itemsCount;
  }, [large, clientWidth, itemsCount, scrollData]);

  const [scrollSpring, setScrollSpring] = useSpring(() => ({
    x: 0,
  }));

  const onScroll = useCallback(
    ({ target }: { target: HTMLDivElement }) => {
      if (containerRef.current) {
        const scrolledPercent =
          (target.scrollLeft * 100) / (target.scrollWidth - target.clientWidth);

        const amountToScroll =
          ((containerRef.current.clientWidth - indicatorWidth) *
            scrolledPercent) /
          100;

        setScrollSpring({ x: amountToScroll });
      }
    },
    [listRef.current, containerRef.current, indicatorWidth],
  );

  useEffect(() => {
    if (scrollData && containerRef.current) {
      const amountToScroll =
        ((clientWidth - indicatorWidth) * scrollData.percentage) / 100;

      setScrollSpring({ x: amountToScroll });
    }
  }, [scrollData, indicatorWidth, containerRef.current]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (listRef.current) {
        listRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, [listRef.current]);

  useResizeObserver({
    ref: containerRef,
    onResize: () => {
      setClientWidth(containerRef.current?.clientWidth || 0);
    },
  });

  return (
    <StyledListScroll ref={containerRef} visible={visible}>
      <StyledScrollIndicator
        style={{ width: indicatorWidth, ...scrollSpring }}
      />
    </StyledListScroll>
  );
};
