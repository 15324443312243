import React from 'react';
import { Hero } from 'layout/hero';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { Badge } from 'core/badge';
import { FlexContainer } from 'layout/flex-container';

export const HeroSection = () => (
  <Hero
    backgroundImageSrc={{
      lg: 'pages/gift-cards/hero.jpg',
      sm: 'pages/gift-cards/hero-small.jpg',
    }}
    height={{
      lg: `calc(100vh - 18rem)`,
      md: '75rem',
    }}
    scrollable={false}
    content={
      <Grid
        height={{ lg: '100%', md: 'unset' }}
        alignContent="center"
        justifyItems={{ lg: 'start', md: 'center' }}
        maxWidth={{ lg: '46rem', md: 'unset' }}
        padding="unset"
        paddingTop={{ lg: 'unset', md: 's5' }}
        horizontalMargin={{ lg: '8.33%', md: 's3', sm: 's1' }}
        rowGap={{ lg: 's4', md: 's3' }}>
        <Cell>
          <FlexContainer
            direction="column"
            alignItems={{ lg: 'left', md: 'center' }}>
            <Badge
              tx="common.new"
              color="white"
              variant="h5"
              textStyle="regular"
            />

            <Typography
              variant="h1"
              tx="giftCards:title"
              color="white"
              align={{ lg: 'left', md: 'center' }}
            />
          </FlexContainer>
        </Cell>
        <Cell>
          <Typography
            variant="h3"
            tx="giftCards:description"
            color="lightGrey"
            textStyle="regular"
            align={{ lg: 'left', md: 'center' }}
          />
        </Cell>
        <Cell>
          <Button
            tx="giftCards:shopNowBtn"
            color="white"
            width="20rem"
            linkHref="/shop/gift-cards"
          />
        </Cell>
      </Grid>
    }
  />
);
