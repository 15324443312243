import React from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';

export const AboutSection = () => {
  return (
    <Grid
      horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}
      verticalPadding="s5"
      columns={{ lg: '1fr 4fr 1fr 5fr 1fr', md: '1fr' }}
      backgroundColor="opaqueGreen"
      alignItems="center"
      gap="unset"
      rowGap="s4">
      <Cell column={{ lg: 2, md: 1 }} row={{ lg: 1, md: 2 }}>
        <ImageContainer src="pages/reach/about.jpg" aspectRatio="1:1.25" />
      </Cell>
      <Cell column={{ lg: 4, md: 1 }}>
        <StackedContainer padding="unset" gap={{ lg: 's4', md: 's3' }}>
          <Typography
            variant="h2"
            textStyle="regular"
            tx="reach:about.title"
            color="sunrise"
            whiteSpace="pre-line"
          />

          <Typography
            tx="reach:about.description"
            color="white"
            whiteSpace="pre-line"
          />
        </StackedContainer>
      </Cell>
    </Grid>
  );
};
