import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Button } from 'core/button';
import { Grid } from 'layout/grid';
import { ImageContainer } from 'layout/image-container';
import Link from 'next/link';
import { InstagramSectionProps } from 'layout/instagram-section/instagram-section.props';
import { InstagramSectionPresets } from 'layout/instagram-section/instagram-section.presets';

export const InstagramSection = (props: InstagramSectionProps) => {
  const {
    logo,
    name,
    backgroundColor,
    buttonTextColor,
    images = [],
  } = Object.assign({}, InstagramSectionPresets, props);

  return (
    <StackedContainer
      backgroundColor={backgroundColor}
      verticalPadding={{ lg: 's6', md: 's5' }}
      horizontalPadding={{ lg: '25%', md: 's3', sm: 's1' }}
      gap={{ lg: 's5', md: 's4' }}>
      <StackedContainer
        flow={{ lg: 'column', md: 'row' }}
        padding="unset"
        justifyContent={{ lg: 'space-between', md: 'center' }}
        justifyItems={{ lg: 'unset', md: 'center' }}
        rowGap="s2">
        <TypographyWithIcon
          text={name}
          icon={logo}
          paddingLeft={{ lg: '1rem', md: '2px' }}
          color="white"
          variant="h3"
          type="p"
        />

        <Link href={`https://www.instagram.com/${name}`} prefetch={false}>
          <a target="_blank">
            <Button
              tx="reach:instagram.followBtn"
              color="white"
              textColor={buttonTextColor}
              width="18rem"
            />
          </a>
        </Link>
      </StackedContainer>

      <Grid
        columns={{ lg: 3, md: 2 }}
        gap={{ lg: 's1', md: '1px' }}
        padding="unset">
        {images.map((item: string, index: number) => (
          <ImageContainer
            key={item}
            src={item}
            aspectRatio="1:1"
            hidden={{ lg: false, md: index > 3 }}
            backgroundColor={backgroundColor}
          />
        ))}
      </Grid>
    </StackedContainer>
  );
};
