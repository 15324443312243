import React, { useEffect } from 'react';
import { Page } from 'core/page';
import { Hero } from 'layout/hero';
import { GetStartedSection } from 'modules/shop/refer/sections';
import { useAuth } from 'context/auth.context';
import { useSignInModal } from 'modals/sign-in';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { StyledReferred } from 'modules/shop/refer/refer.styles';
import { StackedContainer } from 'layout/stacked-container';
import { Formik } from 'formik';
import { TextInput } from 'core/text-input';
import { Button } from 'core/button';
import { TextArea } from 'core/text-area';
import { Checkbox } from 'core/checkbox';
import { ReferFormSchema } from 'modules/shop/refer/refer-form.schema';
import { useCopyToClipboard } from 'react-use';
import { useNotification } from 'context/notification.context';
import { api } from 'services/api';
import { ReferralRequest } from 'services/api/user/user-api.types';
import {
  FacebookMessengerShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share';
import { environment } from 'config/environment';
import { Thumbnail } from 'core/thumbnail';
import ChatIcon from 'assets/images/pages/refer/share-chat.png';
import FacebookIcon from 'assets/images/pages/refer/share-facebook.png';
import MessengerIcon from 'assets/images/pages/refer/share-messenger.png';
import TwitterIcon from 'assets/images/pages/refer/share-twitter.png';
import { BoxContainer } from 'layout/box-container';
import { trackAnalyticsEvent } from 'utils/analytics';
import { useCookies } from 'react-cookie';
import { AUTH_TOKEN } from 'constants/common';

const shareMessage =
  'Check out these stylish lights I found at Gantri. Order one for yourself via this link you’ll save $20.';

export const initial: ReferralRequest = {
  email: '',
  body:
    'Check out these stylish lights I found at Gantri. Order one for yourself via this link you’ll save $20.',
  resendEmail: false,
};

export const ReferPage = () => {
  const [{ authenticated, user }] = useAuth();
  const { showModal } = useSignInModal({
    signUpEventName: 'Sign Up - Referral Refer',
  });
  const [, copyToClipboard] = useCopyToClipboard();
  const { notify, notifyError } = useNotification();
  const [cookies] = useCookies([AUTH_TOKEN]);

  useEffect(() => {
    trackAnalyticsEvent(
      cookies[AUTH_TOKEN]
        ? 'Referral Post-auth Page'
        : 'Referral Pre-auth Page',
    );
  }, []);

  const copyLink = () => {
    copyToClipboard(user.referralLink);

    notify({
      messageTx: 'common.linkCopied',
    });

    trackAnalyticsEvent('Referral Copy Link');
  };

  const refer = async (values: ReferralRequest) => {
    const { success, data, error } = await api.user.refer(values);

    if (success) {
      notify(data.notice);
    } else {
      notifyError(error?.message);
    }
  };

  return (
    <Page
      headerLocked={true}
      hero={
        <Hero
          backgroundImageSrc="pages/refer/banner.jpg"
          height={{ lg: '25vw', md: '50vw' }}
          scrollable={false}
        />
      }>
      {!authenticated && <GetStartedSection onGetStarted={showModal} />}

      {authenticated && (
        <Grid
          columns={{ lg: '1fr 4fr 2fr 4fr 1fr', md: '1fr' }}
          verticalPadding={{ lg: 's6', md: 's5' }}
          horizontalPadding={{ lg: '8.33%', sm: 's1' }}
          rowGap={{ md: 's5', sm: 's4' }}>
          <Cell column={{ lg: 2, md: 1 }}>
            <StackedContainer gap="s1" padding="unset">
              <div>
                <Typography tx="refer:title" variant="h2" />
                <Typography tx="refer:subtitle" variant="h2" />
              </div>

              <Typography tx="refer:description" variant="p2" color="grey" />

              <StyledReferred>
                <Typography tx="refer:friendsReferred" color="grey" />
                <Typography variant="h2" text={user?.addedReferrals} />
              </StyledReferred>
            </StackedContainer>
          </Cell>
          <Cell column={{ lg: 4, md: 1 }}>
            <Formik
              initialValues={{ ...initial, link: user?.referralLink }}
              enableReinitialize={true}
              validationSchema={ReferFormSchema}
              validateOnMount={true}
              validateOnChange={true}
              onSubmit={refer}>
              {props => (
                <form onSubmit={props.handleSubmit}>
                  <StackedContainer
                    gap={{ lg: 's4', sm: 's3' }}
                    padding="unset">
                    <TextInput
                      labelTx="refer:form.link"
                      name="link"
                      readonly={true}
                      value={user?.referralLink}
                      actionTx="refer:form.copy"
                      onActionClick={copyLink}
                    />

                    <TextInput
                      labelTx="refer:form.email"
                      placeholderTx="refer:form.emailPlaceholder"
                      name="email"
                      value={props.values.email}
                      error={props.errors.email}
                      touched={props.touched.email}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />

                    <TextArea
                      labelTx="refer:form.message"
                      placeholderTx="refer:form.messagePlaceholder"
                      name="body"
                      value={props.values.body}
                      error={props.errors.body}
                      touched={props.touched.body}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />

                    <Checkbox
                      name="resendEmail"
                      labelTx="refer:form.reminder"
                      checked={props.values.resendEmail}
                      onChange={value => {
                        props.setFieldValue('resendEmail', value);
                        trackAnalyticsEvent('Referral Toggle Reminder', {
                          value,
                        });
                      }}
                    />

                    <Button
                      tx="refer:form.sendEmailBtn"
                      type="submit"
                      disabled={!props.isValid}
                      analyticsEvent="Referral Send Email"
                      onClick={props.handleSubmit}
                    />

                    <BoxContainer marginTop="s4">
                      <Typography tx="refer:form.share" textStyle="bold" />

                      <Grid
                        padding="unset"
                        gap="s1"
                        columns="repeat(4, 4.8rem)"
                        marginTop="s2">
                        <a
                          href={`sms:?&body=${shareMessage} ${user.referralLink}`}
                          target="_blank"
                          onClick={() =>
                            trackAnalyticsEvent('Referral Share SMS')
                          }>
                          <Thumbnail src={ChatIcon} size="4.8rem" />
                        </a>

                        <BoxContainer hidden={{ lg: true, md: false }}>
                          <FacebookMessengerShareButton
                            url={user.referralLink}
                            appId={environment.FACEBOOK_APP_ID}
                            onClick={() =>
                              trackAnalyticsEvent('Referral Share Messenger')
                            }>
                            <Thumbnail src={MessengerIcon} size="4.8rem" />
                          </FacebookMessengerShareButton>
                        </BoxContainer>

                        <TwitterShareButton
                          url={user.referralLink}
                          title={shareMessage}
                          via="gantridesigns"
                          hashtags={['gantrilight']}
                          onClick={() =>
                            trackAnalyticsEvent('Referral Share Twitter')
                          }>
                          <Thumbnail src={TwitterIcon} size="4.8rem" />
                        </TwitterShareButton>

                        <FacebookShareButton
                          url={user.referralLink}
                          quote={shareMessage}
                          onClick={() =>
                            trackAnalyticsEvent('Referral Share Facebook')
                          }>
                          <Thumbnail src={FacebookIcon} size="4.8rem" />
                        </FacebookShareButton>
                      </Grid>
                    </BoxContainer>
                  </StackedContainer>
                </form>
              )}
            </Formik>
          </Cell>
        </Grid>
      )}
    </Page>
  );
};
