import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import range from 'lodash/range';

const items = range(1, 5);

export const DesignSection = () => (
  <StackedContainer
    verticalPadding={{ lg: 's6', md: 's5' }}
    horizontalPadding={{ lg: '8.33%', md: 's3', sm: 's1' }}
    backgroundColor={'opaqueGreen'}
    rowGap={{ lg: 's6', md: 's4' }}>
    <StackedContainer
      padding="unset"
      alignContent="center"
      justifyContent="center"
      justifyItems="center">
      <Typography tx="reach:design.heading" color="white" variant="h5" />
      <Typography
        tx="reach:design.title"
        variant="h2"
        color="sunrise"
        textStyle="regular"
      />
      <Typography
        tx="reach:design.description"
        color="white"
        align="center"
        maxWidth="67.5rem"
        paddingTop={{ lg: 's3', md: 's2' }}
      />
    </StackedContainer>

    <Grid columns={2} padding="unset">
      {items.map(item => (
        <Cell key={item}>
          <ImageContainer
            src={`pages/reach/organic-${item}.jpg`}
            aspectRatio="1:1"
            backgroundColor="white"
          />
        </Cell>
      ))}
    </Grid>
  </StackedContainer>
);
