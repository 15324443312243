import styled, { css } from 'styled-components';
import { Typography } from 'core/typography';
import { media } from 'styles/media';

export const StyledDesignerName = styled(Typography)`
  position: relative;
  top: 3px;
  padding-right: 1rem;
  display: inline;
`;

export const StyledAwardsContainer = styled.div`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.s5};
    margin-bottom: -${theme.spacing.s2};

    ${media.lessThan('md')`
      margin-top: ${theme.spacing.s3};
    `}

    & > div {
      float: left;
      margin-bottom: ${theme.spacing.s2};
      margin-right: ${theme.spacing.s3};
    }
  `}
`;
