import { useAsync } from 'react-use';
import { useCart } from 'context/cart.context';
import { api } from 'services/api';
import { useAuth } from 'context/auth.context';
import { CheckoutShipping } from 'modules/shop/checkout/checkout.types';

export interface UseSyncCartTaxesProps {
  address: CheckoutShipping;
}

export const useSyncCartTaxes = (props?: UseSyncCartTaxesProps) => {
  const [{ cartSummary, records, credit, giftCode }, cartDispatch] = useCart();
  const [{ user }] = useAuth();

  const selectedAddress =
    props?.address || (user?.addresses?.length ? user.addresses[0] : null);

  useAsync(async () => {
    if (cartSummary.subtotal && selectedAddress && selectedAddress.street) {
      const {
        data: { success, data },
      } = await api.user.fetchTaxes({
        address: selectedAddress,
        credit: credit,
        gift: giftCode?.amount ?? 0,
        giftCode: giftCode?.code ?? '',
        items: records.map(record => ({
          type: record.type,
          sku: record.sku || '',
          amount: { subtotal: record.price || record.amount },
        })),
      });

      cartDispatch({ type: 'SET_TAX', value: success ? data.tax : null });
    } else {
      cartDispatch({ type: 'SET_TAX', value: 0 });
    }
  }, [cartSummary.subtotal, credit, giftCode, selectedAddress?.street]);
};
