import React from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { ImageContainer } from 'layout/image-container';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';

export const SizingSection = () => {
  return (
    <Grid
      columns={{ lg: '1fr 3fr 1fr 5fr 1fr', md: '1fr' }}
      backgroundColor="greyGreen"
      gap={{ lg: 'unset', md: 's4' }}
      verticalPadding={{ lg: 's6', md: 's5' }}
      horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}>
      <Cell column={{ lg: 2, md: 1 }}>
        <StackedContainer
          padding="unset"
          alignContent="center"
          justifyItems={{ lg: 'unset', md: 'center' }}>
          <Typography
            tx="reach:sizing.heading"
            color="white"
            variant="h5"
            fontSize="1.4rem"
            align={{ lg: 'left', md: 'center' }}
          />
          <Typography
            tx="reach:sizing.title"
            color="sunrise"
            variant="h2"
            textStyle="regular"
            maxWidth={{ lg: '29rem', sm: '24rem' }}
            paddingBottom={{ lg: 's3', md: 's2' }}
            align={{ lg: 'left', md: 'center' }}
          />
          <Typography
            tx="reach:sizing.description"
            color="white"
            align={{ lg: 'left', md: 'center' }}
          />
        </StackedContainer>
      </Cell>
      <Cell column={{ lg: 4, md: 1 }}>
        <ImageContainer
          src="pages/reach/optimized-photo.jpg"
          aspectRatio="1:1.25"
        />
      </Cell>
    </Grid>
  );
};
