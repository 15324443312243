import React, { useEffect, useState } from 'react';
import { PreviewProps } from 'modules/shop/reach/sections/preview/preview.props';
import { ImageContainer } from 'layout/image-container';
import {
  StyledFloorPreviewButtonContainer,
  StyledTablePreviewButtonContainer,
  StyledWallPreviewButtonContainer,
} from './preview.styles';
import { Tooltip } from 'core/tooltip';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { useMediaLarge } from 'utils/hooks';
import { StackedContainer } from 'layout/stacked-container';
import { SKU } from 'types/product.type';
import { FlexContainer } from 'layout/flex-container';
import { Typography } from 'core/typography';
import { formatCurrency } from 'utils/formaters';
import { ColorsList } from 'core/colors-list';
import { IconType } from 'core/icon/icon.type';
import { ThemeColor } from 'styles/theme';

export const PreviewTooltip = ({
  product,
  selectedColor,
}: {
  product: SKU;
  selectedColor: ThemeColor;
}) => {
  if (!product) {
    return null;
  }

  const generateColorUrl = color => {
    return `/products/${product.id}/${product.fullUrlName}/${product.id}-${
      product.size.code
    }-${color}${product.variant ? `-${product.variant}` : ''}`;
  };

  return (
    <StackedContainer padding="unset" gap="unset" borderRadius="6rem">
      <FlexContainer justifyContent="space-between">
        <Typography text={product.fullProductName} color="opaqueGreen" />
        <Typography text={formatCurrency(product.price)} color="grey" />
      </FlexContainer>

      <Typography
        text={`${selectedColor} ${product.dimension?.height}" tall`}
        color="greyGreen"
        variant="p3"
        paddingBottom="s2"
      />

      <ColorsList
        colors={product.options?.colors?.map(color => color.code)}
        optionHref={() => '/products/[id]/[skuInfo]/[sku]'}
        optionAs={generateColorUrl}
      />
    </StackedContainer>
  );
};

export const PreviewSection = (props: PreviewProps) => {
  const large = useMediaLarge();
  const [tableLight, setTableLight] = useState(null);
  const [floorLight, setFloorLight] = useState(null);
  const [wallLight, setWallLight] = useState(null);
  const [iconName, setIconName] = useState<IconType>('cart');

  useEffect(() => {
    setTableLight(props.skus.find(item => item.id === 10079));
    setFloorLight(props.skus.find(item => item.id === 10080));
    setWallLight(props.skus.find(item => item.id === 10075));
  }, [props.skus]);

  useEffect(() => {
    setIconName(large ? 'cart' : 'arrow-right');
  }, [large]);

  return (
    <ImageContainer
      src={{
        lg: 'pages/reach/shop.jpg',
        md: 'pages/reach/shop.jpg',
        sm: 'pages/reach/shop-small.jpg',
      }}
      aspectRatio={{ lg: '1:1', md: '1:1.25' }}>
      <StyledTablePreviewButtonContainer>
        <Tooltip
          content={
            <PreviewTooltip product={tableLight} selectedColor="Carbon" />
          }
          visibleOnMobile={false}>
          <Button
            text="Palm Task Light"
            color="white"
            textColor="opaqueGreen"
            minWidth="max-content"
            textVariant="p3"
            icon={<Icon name={iconName} height="1.8rem" color="opaqueGreen" />}
            iconLocation={large ? 'left' : 'right'}
            linkHref="/products/10079/palm-table-light-by-reach/10079-lg-sunrise"
            height="3.2rem"
          />
        </Tooltip>
      </StyledTablePreviewButtonContainer>

      <StyledWallPreviewButtonContainer>
        <Tooltip
          content={
            <PreviewTooltip product={wallLight} selectedColor="Sunrise" />
          }
          visibleOnMobile={false}>
          <Button
            text="Palm Wall Light"
            color="white"
            width="15rem"
            textVariant="p3"
            minWidth="max-content"
            icon={<Icon name={iconName} height="1.8rem" color="opaqueGreen" />}
            iconLocation={large ? 'left' : 'right'}
            linkHref="/products/10075/palm-wall-light-by-reach/10075-lg-snow"
            height="3.2rem"
          />
        </Tooltip>
      </StyledWallPreviewButtonContainer>

      <StyledFloorPreviewButtonContainer>
        <Tooltip
          content={
            <PreviewTooltip product={floorLight} selectedColor="Carbon" />
          }
          visibleOnMobile={false}>
          <Button
            text="Palm Floor Light"
            color="white"
            width="15rem"
            textVariant="p3"
            minWidth="max-content"
            icon={<Icon name={iconName} height="1.8rem" color="opaqueGreen" />}
            iconLocation={large ? 'left' : 'right'}
            linkHref="/products/10080/palm-floor-light-by-reach/10080-lg-snow"
            height="3.2rem"
          />
        </Tooltip>
      </StyledFloorPreviewButtonContainer>
    </ImageContainer>
  );
};
