import React, { useEffect } from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { awardImages } from 'modules/shop/product/sections/designer';
import { ImageContainer } from 'layout/image-container';
import { Thumbnail } from 'core/thumbnail';
import { useTheme } from 'styled-components';
import { Badge } from 'core/badge';
import { useMediaLarge, useMounted } from 'utils/hooks';
import { extractSentences } from 'utils/string';
import { useToggle } from 'react-use';
import { DesignerInfo } from 'types/user.type';
import {
  StyledAwardsContainer,
  StyledDesignerName,
} from 'modules/shop/designer/sections/info/info.styles';
import { BoxContainer } from 'layout/box-container';
import { Button } from 'core/button';
import { Icon } from 'core/icon';
import { useAuth } from 'context/auth.context';
import { Spacer } from 'layout/spacer';
import { TypographyWithIcon } from 'core/typography-with-icon';

interface InfoSectionProps {
  designer: DesignerInfo;
  backgroundColor: string;
  highlightColor: string;
  onShare: () => void;
  onAddToFavorites: () => void;
}

const getBadgeText = (badges): string => {
  if (badges.popular) {
    return 'storefront:badges.popular';
  } else if (badges.awardWinner) {
    return 'storefront:badges.awardWinner';
  } else if (badges.newDesigner) {
    return 'storefront:badges.newDesigner';
  }
  return '';
};

export const InfoSection = ({
  designer,
  backgroundColor,
  highlightColor,
  onShare,
  onAddToFavorites,
}: InfoSectionProps) => {
  const [bioCollapsed, toggleCollapsed] = useToggle(false);
  const theme = useTheme();
  const large = useMediaLarge();
  const mounted = useMounted();
  const [{ user }] = useAuth();

  useEffect(() => {
    toggleCollapsed(large);
  }, [large]);

  const isFavorite = user?.favoriteList?.includes(designer?.userId);
  const hasBadge =
    designer?.badges?.awardWinner ||
    designer?.badges?.newDesigner ||
    designer?.badges?.popular;

  return (
    <Grid
      backgroundColor={backgroundColor}
      columns={{ lg: 2, md: 1 }}
      verticalPadding={{ lg: 's5', sm: 's3' }}
      rowGap="s3">
      <div>
        <Grid
          padding="unset"
          columns={{
            lg: designer.logoUrl ? '12rem 1fr' : '1fr',
            sm: designer.logoUrl ? '6rem 1fr' : '1fr',
          }}
          columnGap={{ lg: 's3', md: 's2', sm: 's1' }}
          alignItems="center"
          alignContent="center">
          {!!designer.logoUrl && (
            <Thumbnail
              src={designer.logoUrl}
              backgroundColor="white"
              border={`1px solid ${theme.colors.lightGrey}`}
              size={{ lg: '12rem', sm: '6rem' }}
              alt={`${designer?.name} Logo`}
            />
          )}

          <div>
            {hasBadge && (
              <>
                <Badge
                  tx={getBadgeText(designer.badges)}
                  color={highlightColor}
                  hidden={{ lg: true, md: false }}
                />

                <Spacer hidden={{ lg: true, md: false }} height="2px" />
              </>
            )}

            <StyledDesignerName variant="h2" type="h1" text={designer.name} />

            {hasBadge && (
              <Badge
                tx={getBadgeText(designer.badges)}
                color={highlightColor}
                hidden={{ lg: false, md: true }}
              />
            )}

            <BoxContainer marginTop="s1" hidden={{ lg: false, md: true }}>
              <Typography variant="p1" text={designer?.title} />
              <Typography
                text={`${designer?.city}, ${
                  designer?.state || designer?.countryName
                }`}
                color="grey"
              />
            </BoxContainer>
          </div>

          <Cell width={2} hidden={{ lg: true, md: false }}>
            <Typography variant="p1" text={designer?.title} />
            <Typography
              text={`${designer?.city}, ${
                designer?.state || designer?.countryName
              }`}
              color="grey"
            />
          </Cell>
        </Grid>

        <Grid
          padding="unset"
          columns="max-content 1fr"
          columnGap={{ lg: 's3', md: 's2' }}
          paddingTop={{ lg: 's5', md: 's3' }}
          alignContent="start">
          <Button
            tx={
              isFavorite
                ? 'storefront:info.favoritedBtn'
                : 'storefront:info.favoriteBtn'
            }
            variant={isFavorite ? 'secondary' : 'primary'}
            width="unset"
            color={highlightColor}
            textColor={isFavorite ? highlightColor : 'white'}
            icon={
              <Icon
                name="heart"
                color={isFavorite ? highlightColor : 'white'}
                size="1.6rem"
              />
            }
            onClick={onAddToFavorites}
          />

          <Button
            variant="action"
            tx="storefront:shareBtn"
            textStyle="regular"
            color={highlightColor}
            analyticsEvent="Designer Share Modal"
            analyticsEventType="page"
            icon={<Icon name="share" color={highlightColor} size="1.6rem" />}
            onClick={onShare}
          />
        </Grid>
      </div>
      <Cell>
        <div>
          <Typography
            variant="p1"
            text={bioCollapsed ? designer.bio : extractSentences(designer.bio)}
            type="span"
            whiteSpace={'pre-wrap'}
          />
          <Typography
            color="primary"
            variant="p1"
            type="span"
            tx={bioCollapsed ? 'storefront:readLess' : 'storefront:readMore'}
            display={mounted && large ? 'none' : 'unset'}
            onClick={toggleCollapsed}
          />
        </div>

        {!!designer?.awards?.length && (
          <StyledAwardsContainer>
            {designer.awards.map(award => (
              <TypographyWithIcon
                key={award.code}
                text={award.award}
                color="black"
                variant="p3"
                icon={
                  <ImageContainer
                    src={awardImages[award.code]}
                    width="3.2rem"
                    height="3.2rem"
                    backgroundColor="transparent"
                  />
                }
              />
            ))}
          </StyledAwardsContainer>
        )}
      </Cell>
    </Grid>
  );
};
