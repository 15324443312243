import React from 'react';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import { Button } from 'core/button';

export const CardUpsellSection = () => {
  return (
    <StackedContainer
      gap={{ lg: 's5', md: 's4' }}
      verticalPadding={{ lg: 's6', md: 's5' }}
      justifyItems="center"
      backgroundColor="primary"
      flow={{ lg: 'column', md: 'row' }}
      justifyContent="center">
      <ImageContainer
        width={{ lg: '50rem', md: '40rem', sm: '100vw' }}
        height={{ lg: '30rem', md: '25rem', sm: '60vw' }}
        src="pages/gift-cards/cards.png"
        backgroundColor="unset"
      />

      <StackedContainer
        padding="unset"
        gap={{ lg: 's4', md: 's3' }}
        alignContent="center">
        <Typography
          tx="giftCards:title"
          variant="h2"
          align="center"
          color="white"
        />

        <Button
          tx="giftCards:shopNowBtn"
          color="white"
          linkHref="/shop/gift-cards"
        />
      </StackedContainer>
    </StackedContainer>
  );
};
