import React, { useEffect, useState } from 'react';
import { ScrollableList } from 'core/scrollable-list';
import { ProductCard } from 'layout/product-card';
import { SKU } from 'types/product.type';
import { ColorsList } from 'core/colors-list';
import { Typography } from 'core/typography';
import { TypographyWithIcon } from 'core/typography-with-icon';
import { Badge } from 'core/badge';
import { StackedContainer } from 'layout/stacked-container';
import { FlexContainer } from 'layout/flex-container';

export interface ProductsSectionProps {
  records: SKU[];
}

export const ProductsSection = (props: ProductsSectionProps) => {
  const { records } = props;
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState('sunrise');

  useEffect(() => {
    if (records?.length) {
      setColors(records[0].options.colors.map(color => color.code));
    }
  }, [records]);

  const renderItem = (position: number) => (
    <ProductCard
      product={records[position]}
      selectedColor={selectedColor}
      designerVisible={false}
      showNewBadge={false}
      canBeWhitelisted={false}
      nameTypographyProps={{ color: 'opaqueGreen' }}
      descriptionTypographyProps={{ color: 'greyGreen' }}
      priceTypographyProps={{ color: 'opaqueGreen' }}
    />
  );

  return (
    <StackedContainer verticalPadding={{ lg: 's6', md: 's5' }}>
      <FlexContainer
        direction="column"
        alignItems="center"
        paddingBottom={{ lg: 's5', md: 's4' }}>
        <Typography
          variant="h2"
          tx="reach:products.title"
          color="opaqueGreen"
          textStyle="regular"
          paddingBottom={{ lg: 's4', md: 's3' }}
          align={{ lg: 'left', md: 'center' }}
          maxWidth={{ lg: 'unset', sm: '35rem' }}
        />

        <ColorsList
          style={{ justifyContent: 'center' }}
          colors={colors}
          selected={selectedColor}
          onChange={setSelectedColor}
        />

        <TypographyWithIcon
          text={selectedColor}
          transform="capitalize"
          color="opaqueGreen"
          icon={
            selectedColor === 'sunrise' && (
              <Badge
                tx="reach:products.badge"
                color="opaqueGreen"
                top="5px"
                variant="h5"
                textStyle="regular"
              />
            )
          }
          iconPosition="right"
          paddingTop="s1"
        />
      </FlexContainer>

      <ScrollableList
        itemsCount={records.length}
        renderItem={renderItem}
        itemsPerPage={{ lg: 3, md: 1.5 }}
        gapVisible={true}
        padding={{ lg: '8.33%', md: 'unset' }}
        hasHorizontalPadding={{ lg: false, md: true }}
      />
    </StackedContainer>
  );
};
