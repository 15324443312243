import React from 'react';
import { CardDesignsProps } from 'modules/shop/gift-cards/sections/card-designs/card-designs.props';
import { StackedContainer } from 'layout/stacked-container';
import { GiftCardDesign } from 'types/gift-card.type';
import { ImageContainer } from 'layout/image-container';
import {
  StyledCard,
  StyledCardContainer,
  StyledStartButton,
} from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { Grid } from 'layout/grid';
import { Typography } from 'core/typography';
import Link from 'next/link';

export const CardDesigns = (props: CardDesignsProps) => {
  const { designs } = props;

  return (
    <StackedContainer
      backgroundColor="cream"
      verticalPadding={{ lg: 's6', md: 's5' }}
      gap={{ lg: 's4', md: 's3' }}
      justifyItems="center">
      <Typography variant="h2" tx="giftCards:designs.title" align="center" />

      <Typography
        variant="p1"
        tx="giftCards:designs.description"
        color="grey"
        align="center"
        maxWidth="67.5rem"
      />

      <Grid
        verticalPadding="unset"
        horizontalPadding={{ lg: 's3', md: 'unset' }}
        columns={{ lg: 3, md: 1 }}
        rowGap="s5"
        width="100%"
        maxWidth="180rem"
        marginTop={{ lg: 's2', md: 's3' }}>
        {designs.map((design: GiftCardDesign) => (
          <StyledCardContainer key={design.id}>
            <StyledCard>
              <ImageContainer
                src={design.photo}
                aspectRatio="1:0.65"
                source="external"
              />

              <StyledStartButton
                tx="giftCards:designs.startDesignBtn"
                color="white"
                width="20rem"
                linkHref={`/shop/gift-cards?design=${design.id}`}
              />
            </StyledCard>

            <StackedContainer
              marginTop={{ lg: 's2', md: 's1', sm: 'unset' }}
              padding="unset"
              gap="s2"
              flow="column"
              justifyItems="center"
              justifyContent="center">
              <ImageContainer
                src={design.designer.photo}
                source="external"
                roundContainer={true}
                width="6rem"
                height="6rem"
                objectFit="scale-down"
              />

              <div>
                <Link
                  href={design.designer.instagramUrl || ''}
                  prefetch={false}>
                  <a target="_blank">
                    <Typography variant="p1" text={design.designer.name} />
                  </a>
                </Link>
                <Typography
                  variant="p3"
                  text={design.designer.location}
                  color="grey"
                />
              </div>
            </StackedContainer>

            <StyledStartButton
              tx="giftCards:designs.startDesignBtn"
              color="white"
              width="20rem"
              linkHref={`/shop/gift-cards?design=${design.id}`}
            />
          </StyledCardContainer>
        ))}
      </Grid>
    </StackedContainer>
  );
};
