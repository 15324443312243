import React, { useMemo } from 'react';
import { BoxContainer } from 'layout/box-container';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { ImageContainer } from 'layout/image-container';
import { Typography } from 'core/typography';
import { Spacer } from 'layout/spacer';
import { StackedContainer } from 'layout/stacked-container';
import { CardPreviewProps } from 'modules/shop/select-gift-card/sections/card-preview/card-preview.props';
import { Icon } from 'core/icon';
import { Button } from 'core/button';
import { useToggle } from 'react-use';
import { FlexContainer } from 'layout/flex-container';
import Link from 'next/link';

export const CardPreview = (props: CardPreviewProps) => {
  const { design, collapsable } = props;
  const [collapsed, toggle] = useToggle(true);

  const showDetails = useMemo(() => {
    return design?.designer?.name !== 'Gantri';
  }, [design]);

  return (
    <>
      <BoxContainer hidden={{ lg: false, md: true }}>
        <StyledCard>
          <ImageContainer
            src={design.photo}
            aspectRatio="1:0.65"
            source="external"
          />
        </StyledCard>
      </BoxContainer>

      <FlexContainer
        justifyContent="center"
        hidden={!collapsable || !showDetails}
        paddingTop={{ lg: 's3', md: 'unset' }}>
        <Button
          variant="action"
          tx={
            collapsable && collapsed
              ? 'giftCards:modal.steps.second.readMore'
              : 'giftCards:modal.steps.second.readLess'
          }
          icon={
            <Icon
              name={collapsable && collapsed ? 'plus-circle' : 'minus-circle'}
              color="primary"
            />
          }
          onClick={toggle}
        />
      </FlexContainer>

      {(!collapsable || (collapsable && !collapsed)) && showDetails && (
        <BoxContainer
          marginTop={{
            lg: collapsable && showDetails ? 's3' : 's5',
            md: collapsable && showDetails ? 's3' : 's2',
          }}>
          <Typography
            variant="h5"
            tx="giftCards:modal.about"
            color="grey"
            align="center"
          />

          <Spacer height="s1" />

          <Typography variant="h3" text={design.title} align="center" />

          <Spacer height={{ lg: 's4', md: 's3' }} />

          <Typography
            text={design.description}
            whiteSpace={'pre-line'}
            color="grey"
            align="center"
          />
        </BoxContainer>
      )}

      {showDetails && (
        <StackedContainer
          padding="unset"
          gap="s2"
          flow="column"
          justifyItems="center"
          justifyContent="center"
          marginTop={{ lg: 's4', md: 's3' }}>
          <ImageContainer
            src={design.designer.photo}
            source="external"
            roundContainer={true}
            width="6rem"
            height="6rem"
            objectFit="scale-down"
          />

          <div>
            <Link href={design.designer.instagramUrl || ''} prefetch={false}>
              <a target="_blank">
                <Typography variant="p1" text={design.designer.name} />
              </a>
            </Link>

            <Typography
              variant="p3"
              text={design.designer.location}
              color="grey"
            />
          </div>
        </StackedContainer>
      )}
    </>
  );
};
