import { theme } from 'styles/theme';

export const reachTheme = Object.assign({}, theme, {
  fonts: {
    boldFontFamily: 'Moderat Bold',
    regularFontFamily: 'Moderat',
    p1: {
      family: 'Moderat',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
    },
    p2: {
      family: 'Moderat',
      size: '1.6rem',
      sizeSmaller: '1.6rem',
      lineHeight: '2.6rem',
      lineHeightSmaller: '2.2rem',
    },
    p3: {
      family: 'Moderat',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
    },
    h1: {
      family: 'Moderat Bold',
      size: '6rem',
      sizeSmaller: '4rem',
      lineHeight: '8rem',
      lineHeightSmaller: '5.2rem',
    },
    h2: {
      family: 'Moderat Bold',
      size: '3.6rem',
      sizeSmaller: '2.8rem',
      lineHeight: '4.8rem',
      lineHeightSmaller: '3.6rem',
    },
    h3: {
      family: 'Moderat Bold',
      size: '2.4rem',
      sizeSmaller: '2rem',
      lineHeight: '3.6rem',
      lineHeightSmaller: '3rem',
    },
    h4: {
      family: 'Moderat Bold',
      size: '1.8rem',
      sizeSmaller: '1.6rem',
      lineHeight: '2.8rem',
      lineHeightSmaller: '2.6rem',
    },
    h5: {
      family: 'Moderat Bold',
      size: '1.2rem',
      sizeSmaller: '1.2rem',
      lineHeight: '2rem',
      lineHeightSmaller: '2rem',
    },
    label: {
      family: 'Moderat',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
    },
    link: {
      family: 'Moderat',
      size: '1.5rem',
      sizeSmaller: '1.4rem',
      lineHeight: '2.5rem',
      lineHeightSmaller: '2rem',
    },
    badge: {
      family: 'Moderat',
      size: '1.2rem',
      sizeSmaller: '1rem',
      lineHeight: '2.2rem',
      lineHeightSmaller: '1.8rem',
    },
  },
});
