import firebase from '@firebase/app';
import '@firebase/auth';
import { createLogger } from '@unly/utils-simple-logger';
import { environment } from 'config/environment';

const logger = createLogger({
  label: 'firebase',
});

export const initializeFirebase = (token: string) => {
  if (!firebase.apps.length && token) {
    firebase.initializeApp({
      apiKey: environment.FIREBASE_KEY,
      authDomain: environment.FIREBASE_DOMAIN,
      storageBucket: environment.FIREBASE_STORAGE_BUCKET,
      projectId: environment.FIREBASE_PROJECT_ID,
    });

    firebase
      .auth()
      .signInWithCustomToken(token)
      .catch(error => {
        logger.error('firebase authentication error: ', error);
      });
  }
};
