import { FloatingContainerProps } from 'layout/floating-container/floating-container.props';
import { FloatingContainerPresets } from 'layout/floating-container/floating-container.presets';
import React, { forwardRef } from 'react';
import { StyledFloatingContainer } from 'layout/floating-container/floating-container.styles';
import { ClickAwareContainer } from 'layout/click-aware-container';

export const FloatingContainer = forwardRef(
  (props: FloatingContainerProps, ref: any) => {
    const {
      children,
      top,
      right,
      bottom,
      left,
      visible,
      onClickOutside,
      ...other
    } = Object.assign({}, FloatingContainerPresets, props);
    return (
      <StyledFloatingContainer
        ref={ref}
        top={top}
        right={right}
        bottom={bottom}
        left={left}
        {...other}>
        {onClickOutside ? (
          <ClickAwareContainer
            onClickOutside={onClickOutside}
            visible={visible}>
            {children}
          </ClickAwareContainer>
        ) : (
          children
        )}
      </StyledFloatingContainer>
    );
  },
);
