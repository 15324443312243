import styled, { css } from 'styled-components';
import { Typography } from '../typography';
import { TextInputProps } from 'core/text-input/text-input.props';
import { getThemeColor } from 'styles/theme';

export const StyledInputContainer = styled.div``;

export const StyledTextArea = styled.textarea<
  TextInputProps & { invalid: boolean; touched: boolean }
>`
  ${({ theme, invalid, touched, disabled, labelColor }) => {
    let color;
    let borderColor;
    const markAsInvalid = invalid && touched;

    if (markAsInvalid) {
      color = getThemeColor('red');
      borderColor = color;
    } else {
      color = getThemeColor(labelColor);
      borderColor = getThemeColor(
        labelColor === 'black' ? 'lightGrey' : labelColor,
      );
    }

    return css`
      overflow: hidden;
      width: 100%;
      min-width: 100%;
      border: none;
      border-bottom: 1px solid ${borderColor};
      border-radius: 0;
      padding: 0 2rem 1rem 0;
      outline: none;
      color: ${color};
      font-size: 1.5rem;
      line-height: 2rem;
      resize: none;
      height: 100%;
      background-color: transparent;
      transition: all ${theme.transitions.base};
      opacity: ${disabled ? 0.5 : 1};
    `;
  }}
`;

export const StyledInputContent = styled.div<{
  invalid?: boolean;
  focused?: boolean;
  touched?: boolean;
  color?: string;
}>`
  ${({ theme, focused, invalid, touched }) => {
    const markAsInvalid = invalid && touched;
    let backgroundColor = getThemeColor(markAsInvalid ? 'red' : 'color');

    return css`
      position: relative;
      display: flex;
      flex: 1;

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        transform: scale(1);
        width: 100%;
        background-color: ${backgroundColor};
        transition: transform ${theme.transitions.slow};

        ${focused &&
        css`
          transform: scale(1);
        `}
      }
    `;
  }}
`;

export const StyledBodyContainer = styled.div`
  position: relative;
  display: flex;
  cursor: pointer;
`;

export const StyledPlaceholder = styled(Typography)`
  position: absolute;
  top: 0;
  pointer-events: none;
`;

export const StyledInputDescription = styled(Typography)`
  position: relative;
  top: -5px;
`;
