import React, { useMemo } from 'react';
import { Typography } from 'core/typography';
import {
  StyledCartSummaryTitle,
  StyledSummaryAffirmInfo,
  StyledSummaryRow,
} from './cart-summary.styles';
import { CartSummaryProps } from 'layout/cart-summary/cart-summary.props';
import { formatCurrency } from 'utils/formaters';
import { StyledContentPanel } from 'layout/common';
import { useAffirm } from 'utils/hooks';

export const CartSummary = (props: CartSummaryProps) => {
  const {
    summary: { shipping, total, tax, credit, giftDiscount, subtotal },
    affirmInfoVisible,
    shippingInfoVisible,
  } = props;

  const { affirmAvailable } = useAffirm({
    apr: '0.15',
    months: 12,
    amount: total,
    loadImmediately: affirmInfoVisible,
  });

  const totalWithoutCredit =
    subtotal + (shipping ?? 0) + (tax ?? 0) - (giftDiscount ?? 0);
  const canShowAffirm = useMemo(
    () =>
      affirmInfoVisible && affirmAvailable && total >= 5000 && total <= 1750000,
    [affirmInfoVisible, affirmAvailable, total],
  );

  return (
    <StyledContentPanel>
      <StyledCartSummaryTitle>
        <Typography
          type="h2"
          variant="h4"
          tx="cartSummary:title"
          textStyle="bold"
        />
      </StyledCartSummaryTitle>

      <StyledSummaryRow>
        <Typography tx="cartSummary:subtotal" color="grey" />
        <Typography text={formatCurrency(subtotal)} color="grey" />
      </StyledSummaryRow>

      {shippingInfoVisible && (
        <StyledSummaryRow>
          <Typography tx="cartSummary:shipping" color="grey" />
          <Typography
            text={shipping ? formatCurrency(shipping ?? 0) : 'Free'}
            color="grey"
          />
        </StyledSummaryRow>
      )}

      <StyledSummaryRow>
        <Typography tx="cartSummary:tax" color="grey" />
        <Typography
          text={tax !== undefined ? formatCurrency(tax) : 'TBD'}
          color="grey"
        />
      </StyledSummaryRow>

      {!!credit && (
        <StyledSummaryRow>
          <Typography tx="cartSummary:credit" color="grey" />
          <Typography
            text={formatCurrency(
              credit >= totalWithoutCredit ? -totalWithoutCredit : -credit,
            )}
            color="grey"
          />
        </StyledSummaryRow>
      )}

      {!!giftDiscount && (
        <StyledSummaryRow>
          <Typography tx="cartSummary:giftDiscount" color="grey" />
          <Typography text={formatCurrency(-giftDiscount)} color="grey" />
        </StyledSummaryRow>
      )}

      <StyledSummaryRow>
        <Typography tx="cartSummary:total" />
        <Typography text={formatCurrency(total)} />
      </StyledSummaryRow>

      {canShowAffirm && (
        <StyledSummaryAffirmInfo
          className="affirm-as-low-as"
          data-amount={total}
          data-page-type="product"
          data-affirm-color="black"
        />
      )}
    </StyledContentPanel>
  );
};
