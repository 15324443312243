import React, { useCallback, useEffect, useState } from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Icon } from 'core/icon';
import { RoundButton } from 'core/round-button';
import {
  StyledCloseButtonContainer,
  StyledSelectGiftCardContainer,
} from 'modules/shop/select-gift-card/select-gift-card.styles';
import { useGiftCard } from 'context/gift-card.context';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';
import { Step, Steps } from 'core/steps';
import {
  AmountSelector,
  CardPreview,
  DesignSelector,
  MessageForm,
  PhonePreview,
} from 'modules/shop/select-gift-card/sections';
import { GiftCardDesign, GiftCardRecord } from 'types/gift-card.type';
import { useAuth } from 'context/auth.context';
import { useCartHelpers } from 'modules/shop/cart/hooks/cart.hooks';
import { v4 as uuidv4 } from 'uuid';
import { formatDate } from 'utils/date';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { ImageContainer } from 'layout/image-container';
import { BoxContainer } from 'layout/box-container';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

export const SelectGiftCardPage = () => {
  const [
    { currentDesign, selectedAmount, designs },
    giftCardDispatch,
  ] = useGiftCard();

  const { addGiftCardToCart } = useCartHelpers();
  const { t } = useTranslation();

  const [currentStep, setCurrentStep] = useState(0);
  const [senderName, setSenderName] = useState('');
  const [message, setMessage] = useState<string>(
    t('giftCards:modal.steps.third.form.defaultMessage'),
  );

  const [{ user, authenticated }] = useAuth();
  const { query, push } = useRouter();

  useEffect(() => {
    if (authenticated && !senderName) {
      setSenderName(user ? `${user?.firstName} ${user?.lastName}` : '');
    }
  }, [authenticated, user, senderName]);

  useEffect(() => {
    if (query.design && designs?.length) {
      const temporal = designs.find(design => design.id === +query.design);

      if (temporal) {
        giftCardDispatch({
          type: 'SET_CURRENT_DESIGN',
          design: temporal,
        });

        setCurrentStep(1);
      }
    }
  }, [query.design, designs]);

  const onDesignSelected = (design: GiftCardDesign) => {
    giftCardDispatch({ type: 'SET_CURRENT_DESIGN', design });
  };

  const onAmountSelected = (amount: number) => {
    giftCardDispatch({ type: 'SET_SELECTED_AMOUNT', amount });
  };

  const previous = () => {
    setCurrentStep(currentStep - 1);
    window.scrollTo(0, 0);
  };

  const next = () => {
    setCurrentStep(currentStep + 1);
    window.scrollTo(0, 0);
  };

  const addToCart = useCallback(
    async (record: GiftCardRecord) => {
      await addGiftCardToCart({
        id: uuidv4(),
        productId: currentDesign.id,
        amount: selectedAmount * 100,
        sendDate: formatDate(record.sendDate, 'MM/DD/YYYY'),
        ...record,
        design: currentDesign,
      });
    },
    [currentDesign, selectedAmount],
  );

  const steps: Step[] = [
    {
      content: (
        <DesignSelector
          designs={designs}
          selectedDesign={currentDesign}
          onDesignSelected={onDesignSelected}
          onNext={next}
        />
      ),
    },
    {
      content: (
        <AmountSelector
          selectedAmount={selectedAmount}
          onAmountSelected={onAmountSelected}
          onPrevious={previous}
          onNext={next}
        />
      ),
    },
    {
      content: (
        <MessageForm
          senderName={senderName}
          message={message}
          onMessageChange={setMessage}
          onSenderNameChange={setSenderName}
          onPrevious={previous}
          onAddToCart={addToCart}
        />
      ),
    },
  ];

  if (!currentDesign) {
    return null;
  }

  return (
    <StyledSelectGiftCardContainer>
      <StackedContainer
        horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}
        verticalPadding="unset"
        width="100%"
        minHeight="100vh"
        gap={{ lg: 's5', md: 's4' }}
        backgroundColor={currentDesign.baseColor}>
        <StyledCloseButtonContainer>
          <RoundButton
            color="lightGrey"
            icon={<Icon name="close" color="white" size="1.2rem" />}
            analyticsEvent="Quiz Close"
            onClick={() => push('/gift-cards')}
          />
        </StyledCloseButtonContainer>

        <BoxContainer
          hidden={{ lg: true, md: currentStep === 2 }}
          marginTop={{ lg: 'unset', md: 's5' }}>
          <StyledCard>
            <ImageContainer
              src={currentDesign.photo}
              aspectRatio="1:0.65"
              source="external"
            />
          </StyledCard>
        </BoxContainer>

        <Grid
          padding="unset"
          columns={{ lg: '1fr 4fr 1fr 44rem 0', md: '1fr' }}
          paddingBottom={{ lg: 's6', md: 's5' }}
          paddingTop={{ lg: 's6', md: currentStep === 2 ? 's5' : 'unset' }}
          rowGap="s4"
          alignItems={currentStep === 2 ? 'center' : 'start'}>
          <Cell column={{ lg: 2, md: 1 }} row={{ lg: 1, md: 2 }}>
            {currentStep === 2 ? (
              <PhonePreview
                amount={selectedAmount}
                sender={senderName}
                message={message}
                photo={currentDesign.photo}
              />
            ) : (
              <CardPreview
                design={currentDesign}
                collapsable={currentStep === 1}
              />
            )}
          </Cell>
          <Cell column={{ lg: 4, md: 1 }} row={{ lg: 1, md: 1 }}>
            <StackedContainer
              backgroundColor="white"
              padding={{ lg: 's3', md: 's2', sm: 's1' }}
              paddingBottom={{ lg: 's5', md: 's4' }}
              borderRadius="6px"
              height="fit-content"
              gap="s1">
              <Typography
                variant="h5"
                tx="giftCards:modal.steps.number"
                txValues={{ current: currentStep + 1 }}
                align="center"
                color="grey"
              />

              <Steps steps={steps} currentStep={currentStep} />
            </StackedContainer>
          </Cell>
        </Grid>
      </StackedContainer>
    </StyledSelectGiftCardContainer>
  );
};
