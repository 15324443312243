import React, { useEffect, useState } from 'react';
import { Page } from 'core/page';
import { useSubscribeModal } from 'modals/subscribe';
import { DesignerProps } from 'modules/shop/designer/designer.props';
import {
  HeroSection,
  InfoSection,
  ProductsSection,
  QaQuestionsSection,
  QuoteSection,
  SocialSection,
} from 'modules/shop/designer/sections';
import { useAsync } from 'react-use';
import { fetchReviews } from 'assets/api';
import { Review } from 'types/review.type';
import { useModal } from 'context/modal.context';
import { ShareModal } from 'modals/share';
import { SocialProfileJsonLd } from 'next-seo';
import { DesignerFavoriteModal } from 'modals/designer-favorite';
import { api } from 'services/api';
import { useSignInModal } from 'modals/sign-in';
import { useAuth } from 'context/auth.context';
import dynamic from 'next/dynamic';
import { useLayout } from 'context/layout.context';
import { Award, DesignerInfo, QA } from 'types/user.type';
import axios, { CancelTokenSource } from 'axios';
import { useNotification } from 'context/notification.context';
import { useRouter } from 'next/router';
import { deleteImage } from 'core/firebase-uploader/firebase-uploader.helpers';
import { initializeFirebase } from 'utils/firebase';
import { useTrackAnalytics } from 'src/common/hooks';
import { LoadingOverlay } from 'layout/loading-overlay';
import { Reviews } from 'layout/reviews';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { BehindTheScenesSection } from 'modules/shop/designer/sections/bething-the-scenes';

const HeroEditSection = dynamic(() => import('./sections/hero-edit'), {
  ssr: false,
});

const InfoEditSection = dynamic(() => import('./sections/info-edit'), {
  ssr: false,
});

const QaQuestionsEditSection = dynamic(
  () => import('./sections/qa-questions-edit'),
  {
    ssr: false,
  },
);

const SocialEditSection = dynamic(() => import('./sections/social-edit'), {
  ssr: false,
});

const QuoteEditSection = dynamic(() => import('./sections/quote-edit'), {
  ssr: false,
});

const BehindTheScenesEditSection = dynamic(
  () => import('./sections/behind-the-scenes-edit'),
  {
    ssr: false,
  },
);

let apiSource: CancelTokenSource;

export const DesignerPage = (props: DesignerProps) => {
  const { records } = props;
  const [current, setCurrent] = useState(props.current);
  const [reviewsData, setReviewsData] = useState<{
    reviews: Review[];
    total: number;
  }>();
  const [{ authenticated, firebaseToken }, authDispatch] = useAuth();
  const {
    editingDesigner,
    designerBackgroundColor,
    designerHighlightColor,
    resetDesignerTheme,
  } = useLayout();
  const { notifyError, showPreloader, hidePreloader } = useNotification();
  const { isFallback, push: routerPush } = useRouter();

  useTrackAnalytics({
    enterEvent: 'Designer Home',
  });

  useEffect(() => {
    if (firebaseToken) {
      initializeFirebase(firebaseToken);
    }
  }, [firebaseToken]);

  const { showModal } = useModal({
    template: (
      <ShareModal
        titleTx="storefront:share.title"
        descriptionTx="storefront:share.description"
        link={process.browser ? window.location.href : ''}
        analyticsIdentifier="Designer"
      />
    ),
    width: {
      lg: '38rem',
      sm: '28rem',
    },
  });

  const { showModal: showFavoriteModal } = useModal({
    template: (
      <DesignerFavoriteModal
        designerName={current?.name}
        photoUrl={current?.logoUrl}
      />
    ),
    width: {
      lg: '46rem',
      sm: '30rem',
    },
  });

  useSubscribeModal();

  useAsync(async () => {
    const skus = records.map(record => record.sku);
    const temporal = await fetchReviews(skus);
    setReviewsData(temporal);
  }, []);

  const editFavorite = async () => {
    const { data } = await api.user.addDesignerToFavorites({
      designerId: current.userId,
    });

    authDispatch({
      type: 'UPDATE_USER_FAVORITES_LIST',
      list: data.data?.favoriteList || [],
    });

    if (data.data?.favoriteList?.includes(current?.userId)) {
      showFavoriteModal();
    }
  };

  const { showModal: showSignInModal } = useSignInModal({
    signUpEventName: 'Sign Up - Designer Favorite',
    onClose: async data => {
      if (data) {
        await editFavorite();
      }
    },
  });

  const onAddToFavorites = async () => {
    if (authenticated) {
      await editFavorite();
    } else {
      showSignInModal();
    }
  };

  const updateDesigner = async (designer: DesignerInfo) => {
    setCurrent(designer);

    if (!apiSource) {
      apiSource = axios.CancelToken.source();
    } else {
      apiSource.cancel();
      apiSource = axios.CancelToken.source();
    }

    const { success, error } = await api.designer.update(designer, {
      cancelToken: apiSource.token,
    });

    if (!success) {
      if (error?.kind !== 'cancelled') {
        notifyError(error?.message);
      }
    }
  };

  useAsync(async () => {
    if (
      designerBackgroundColor &&
      designerHighlightColor &&
      !!current?.theme &&
      (current.theme.background !== designerBackgroundColor ||
        current.theme.highlight !== designerHighlightColor)
    ) {
      const temporal = Object.assign({}, current, {
        theme: {
          background: designerBackgroundColor || current.theme.background,
          highlight: designerHighlightColor || current.theme.highlight,
        },
      } as DesignerInfo);

      await updateDesigner(temporal);
    }
  }, [designerBackgroundColor, designerHighlightColor]);

  useEffect(() => {
    return () => {
      resetDesignerTheme();
    };
  }, []);

  const onImageUploaded = async (url: string) => {
    showPreloader();

    const temporal = Object.assign({}, current, {
      heroImages: [...current.heroImages, url],
    } as DesignerInfo);

    await updateDesigner(temporal);

    hidePreloader();
  };

  const onBehindTheScenesImageUploaded = async (url: string) => {
    showPreloader();

    const temporal = Object.assign({}, current, {
      behindScenesImages: [...current.behindScenesImages, url],
    } as DesignerInfo);

    await updateDesigner(temporal);

    hidePreloader();
  };

  const onImageDeleted = async (url: string) => {
    deleteImage({
      fileUrl: url,
    });

    const temporal = Object.assign({}, current, {
      heroImages: current.heroImages.filter(image => image !== url),
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onBehindTheScenesImageDeleted = async (url: string) => {
    deleteImage({
      fileUrl: url,
    });

    const temporal = Object.assign({}, current, {
      behindScenesImages: current.behindScenesImages.filter(
        image => image !== url,
      ),
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onBioChange = async (value: string) => {
    if (current?.bio !== value) {
      const temporal = Object.assign({}, current, {
        bio: value,
      } as DesignerInfo);

      await updateDesigner(temporal);
    }
  };

  const onAwardChange = async (value: boolean, award: Award) => {
    const temporal = Object.assign({}, current, {
      awards: value
        ? [...current?.awards, award]
        : current?.awards.filter(item => item.code !== award.code),
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onPhotoUploaded = async (url: string) => {
    const temporal = Object.assign({}, current, {
      photoUrl: url,
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const removePhotoUrl = async () => {
    const temporal = Object.assign({}, current, {
      photoUrl: null,
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onPhotoDeleted = async () => {
    deleteImage({
      fileUrl: current.photoUrl,
    });

    await removePhotoUrl();
  };

  const onQaQuestionsChanged = async (qas: QA[]) => {
    const temporal = Object.assign({}, current, {
      QAs: qas.filter(qa => qa.qaAnswer && qa.qaQuestion),
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onQuoteChange = async (value: string) => {
    if (!current?.quotes?.length || current.quotes[0] !== value) {
      const temporal = Object.assign({}, current, {
        quotes: [value, ...current.quotes.slice(1)],
      } as DesignerInfo);

      await updateDesigner(temporal);
    }
  };

  const onHeroImagesSorted = async (sortedImages: string[]) => {
    const temporal = Object.assign({}, current, {
      heroImages: sortedImages,
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  const onBehindTheScenesImagesSorted = async (sortedImages: string[]) => {
    const temporal = Object.assign({}, current, {
      behindScenesImages: sortedImages,
    } as DesignerInfo);

    await updateDesigner(temporal);
  };

  useAsync(async () => {
    if (!isFallback) {
      if (props?.notFound) {
        await routerPush('/404');
      } else {
        setCurrent(props.current);
      }
    }
  }, [isFallback, props.notFound]);

  if (isFallback || !current?.profileLink) {
    return <LoadingOverlay background="white" />;
  }

  const altText = `${current.name}, ${current.city}, ${
    current.state || current.countryName
  }`;

  const title = `${current.name} - ${current.title || ''} in ${
    current.city || ''
  }, ${current.state || current.countryName || ''} | Gantri ®`;

  return (
    <Page
      headerLocked={true}
      hero={
        editingDesigner ? (
          <HeroEditSection
            images={current.heroImages}
            alt={altText}
            backgroundColor={current.theme?.background}
            onImageUploaded={onImageUploaded}
            onImageUploadingError={notifyError}
            onImageDeleted={onImageDeleted}
            onHeroImagesSorted={onHeroImagesSorted}
          />
        ) : (
          <HeroSection
            images={current.heroImages}
            alt={altText}
            backgroundColor={current.theme?.background}
          />
        )
      }
      seoConfig={{
        title,
        description: current.bio,
        openGraph: {
          title,
          description: current.bio,
          images: [
            {
              url: current.photoUrl,
              alt: altText,
            },
          ],
        },
      }}>
      <SocialProfileJsonLd
        type="Person"
        name={current.name}
        url={current.links?.website}
        sameAs={[
          current.links?.twitter,
          current.links?.behance,
          current.links?.instagram,
        ]}
      />

      {editingDesigner ? (
        <InfoEditSection
          designer={current}
          backgroundColor={current.theme?.background}
          highlightColor={current.theme?.highlight}
          onShare={showModal}
          onBioChange={onBioChange}
          onAwardChange={onAwardChange}
          onAddToFavorites={onAddToFavorites}
        />
      ) : (
        <InfoSection
          designer={current}
          backgroundColor={current.theme?.background}
          highlightColor={current.theme?.highlight}
          onShare={showModal}
          onAddToFavorites={onAddToFavorites}
        />
      )}

      {!!records?.length && <ProductsSection skus={records} />}

      {!!reviewsData?.reviews?.length && (
        <Grid
          columns={{ lg: '3fr 6fr 3fr', md: '1fr' }}
          verticalPadding={{ lg: 's6', md: 's5' }}
          backgroundColor={current.theme?.background}>
          <Cell column={{ lg: 2, md: 1 }}>
            <Reviews
              reviews={reviewsData?.reviews || []}
              total={reviewsData?.total || 0}
            />
          </Cell>
        </Grid>
      )}

      {editingDesigner ? (
        <SocialEditSection
          photoUrl={current.photoUrl}
          alt={altText}
          links={current.links}
          backgroundColor="white"
          onPhotoUploaded={onPhotoUploaded}
          onPhotoUploadingError={notifyError}
          onPhotoDeleted={onPhotoDeleted}
        />
      ) : (
        !!current.photoUrl &&
        !!current.links && (
          <SocialSection
            backgroundColor="white"
            photoUrl={current.photoUrl}
            alt={altText}
            links={current.links}
          />
        )
      )}

      {editingDesigner ? (
        <BehindTheScenesEditSection
          images={current.behindScenesImages}
          onImageUploaded={onBehindTheScenesImageUploaded}
          onImageUploadingError={notifyError}
          onImageDeleted={onBehindTheScenesImageDeleted}
          onImagesSorted={onBehindTheScenesImagesSorted}
        />
      ) : !!current.behindScenesImages?.length ? (
        <BehindTheScenesSection images={current.behindScenesImages} />
      ) : null}

      {editingDesigner ? (
        <QaQuestionsEditSection
          questions={current.QAs || []}
          onQaQuestionsChanged={onQaQuestionsChanged}
          backgroundColor={current.theme?.background}
        />
      ) : (
        <QaQuestionsSection
          questions={current.QAs}
          backgroundColor={current.theme?.background}
        />
      )}

      {editingDesigner ? (
        <QuoteEditSection
          quote={!!current?.quotes?.length ? current.quotes[0] : ''}
          highlightColor={current.theme?.highlight}
          onQuoteChange={onQuoteChange}
        />
      ) : (
        !!current?.quotes?.length && (
          <QuoteSection
            quote={current.quotes[0]}
            highlightColor={current.theme?.highlight}
          />
        )
      )}
    </Page>
  );
};
