import styled, { css } from 'styled-components';
import { Button } from 'core/button';
import { StyledImageContainer } from 'layout/image-container/image-container.styles';
import { media } from 'styles/media';

export const StyledStartButton = styled(Button)`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;

  ${media.lessThan('md')`
    opacity: 1;
    position: relative;   
    pointer-events: all;
  `}
`;

export const StyledCardContainer = styled.div`
  display: grid;
  row-gap: 2rem;
  justify-items: center;

  ${StyledStartButton} {
    pointer-events: none;
  }
`;

export const StyledCard = styled.div`
  ${({ theme }) => css`
    box-shadow: ${theme.shadow};
    border-radius: 1.5rem;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    width: 100%;
    max-width: 52rem;

    ${StyledStartButton} {
      position: absolute;
      opacity: 0;
      transition: ${theme.transitions.base};
      pointer-events: all;

      ${media.lessThan('md')`
          display: none;
      `}
    }

    &:hover ${StyledStartButton} {
      opacity: 1;
    }

    &:hover ${StyledImageContainer} {
      opacity: 0.7;
      transition: ${theme.transitions.base};
      pointer-events: none;

      ${media.lessThan('md')`
        opacity: 1;
      `}
    }
  `};
`;
