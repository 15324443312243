import React from 'react';
import { MessageFormProps } from 'modules/shop/select-gift-card/sections/message-form/message-form.props';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Button } from 'core/button';
import { TextInput } from 'core/text-input';
import { Formik } from 'formik';
import { DatePicker } from 'core/date-picker';
import { TextArea } from 'core/text-area';
import { Icon } from 'core/icon';
import { MessageFormSchema } from './message-form.schema';
import { formatDate } from 'utils/date';
import { GiftCardRecord } from 'types/gift-card.type';
import { Cell } from 'layout/cell';
import dayjs from 'dayjs';

const initialValue: GiftCardRecord = {
  recipientName: '',
  recipientEmail: '',
  senderName: '',
  sendDate: formatDate(dayjs().add(1, 'day'), 'MM/DD/YYYY'),
  message: '',
};

export const MessageForm = (props: MessageFormProps) => {
  const {
    senderName,
    message,
    onPrevious,
    onMessageChange,
    onSenderNameChange,
    onAddToCart,
  } = props;

  return (
    <Formik
      initialValues={{ ...initialValue, senderName, message }}
      enableReinitialize={false}
      validationSchema={MessageFormSchema}
      validateOnMount={true}
      validateOnChange={true}
      onSubmit={onAddToCart}>
      {props => (
        <form onSubmit={props.handleSubmit}>
          <StackedContainer padding="unset" gap={{ lg: 's4', md: 's3' }}>
            <Typography
              variant="h3"
              tx="giftCards:modal.steps.third.title"
              align="center"
            />

            <StackedContainer padding="unset" gap="2.5rem" paddingTop="s2">
              <TextInput
                name="recipientName"
                labelTx="giftCards:modal.steps.third.form.recipientName"
                value={props.values.recipientName}
                error={props.errors.recipientName}
                touched={props.touched.recipientName}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              <TextInput
                name="recipientEmail"
                type="email"
                autoComplete="email"
                labelTx="giftCards:modal.steps.third.form.recipientEmail"
                value={props.values.recipientEmail}
                error={props.errors.recipientEmail}
                touched={props.touched.recipientEmail}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />

              <TextInput
                name="senderName"
                labelTx="giftCards:modal.steps.third.form.senderName"
                value={props.values.senderName}
                error={props.errors.senderName}
                touched={props.touched.senderName}
                onChange={props.handleChange}
                onTextChange={onSenderNameChange}
                onBlur={props.handleBlur}
              />

              <DatePicker
                name="sendDate"
                value={props.values.sendDate}
                labelTx="giftCards:modal.steps.third.form.sendDate"
                allowPastDates={false}
                onChange={props.handleChange}
              />

              <TextArea
                name="message"
                labelTx="giftCards:modal.steps.third.form.message"
                value={props.values.message}
                error={props.errors.message}
                touched={props.touched.message}
                onChange={props.handleChange}
                onTextChange={onMessageChange}
                onBlur={props.handleBlur}
              />
            </StackedContainer>

            <Grid padding="unset" columns={2} columnGap="s1" rowGap="s2">
              <Cell>
                <Button
                  tx="giftCards:modal.steps.backBtn"
                  variant="secondary"
                  onClick={onPrevious}
                  minWidth="unset"
                />
              </Cell>
              <Cell>
                <Button
                  tx="giftCards:modal.steps.addToCart"
                  type="submit"
                  icon={<Icon name="cart" color="white" height="2rem" />}
                  minWidth="unset"
                />
              </Cell>

              <Cell width={2}>
                <Typography
                  tx="giftCards:modal.steps.third.form.info"
                  variant="p3"
                  color="grey"
                  align="center"
                />
              </Cell>
            </Grid>
          </StackedContainer>
        </form>
      )}
    </Formik>
  );
};
