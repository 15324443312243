import React from 'react';
import { Hero } from 'layout/hero';

export const HeroSection = ({ images, alt, backgroundColor }) => (
  <Hero
    height={{
      lg: '56.25vw',
      md: '75vw',
    }}
    variant="carousel"
    scrollable={false}
    carouselProps={{
      images,
      imagesSource: 'external',
      indicatorsHidden: false,
      indicatorsPosition: 'center',
      containerBackgroundColor: backgroundColor,
      alt,
    }}
  />
);
