import React from 'react';
import { DesignSelectorProps } from 'modules/shop/select-gift-card/sections/design-selector/design-selector.props';
import { StackedContainer } from 'layout/stacked-container';
import { GiftCardDesign } from 'types/gift-card.type';
import { Typography } from 'core/typography';
import { StyledDesignItem } from 'modules/shop/select-gift-card/sections/design-selector/design-selector.styles';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { ImageContainer } from 'layout/image-container';
import { Button } from 'core/button';
import { FlexContainer } from 'layout/flex-container';

export const DesignSelector = (props: DesignSelectorProps) => {
  const { designs, selectedDesign, onDesignSelected, onNext } = props;

  return (
    <StackedContainer padding="unset" gap={{ lg: 's4', md: 's3' }}>
      <Typography
        variant="h3"
        tx="giftCards:modal.steps.first.title"
        align="center"
      />

      <StackedContainer padding="unset" paddingTop={{ lg: 's2', md: 'unset' }}>
        {designs.map((design: GiftCardDesign) => (
          <StyledDesignItem
            key={design.id}
            selected={selectedDesign?.id === design.id}
            onClick={() => onDesignSelected(design)}>
            <StyledCard>
              <ImageContainer
                src={design.photo}
                aspectRatio="1:0.65"
                source="external"
              />
            </StyledCard>

            <Typography
              variant="p1"
              text={design.title}
              color={selectedDesign?.id === design.id ? 'primary' : 'black'}
            />
          </StyledDesignItem>
        ))}
      </StackedContainer>

      <FlexContainer justifyContent="center">
        <Button tx="giftCards:modal.steps.nextBtn" onClick={onNext} />
      </FlexContainer>
    </StackedContainer>
  );
};
