import React from 'react';
import { ColoredContainer } from 'layout/colored-section';
import { FlexContainer } from 'layout/flex-container';
import { Button } from 'core/button';

export const GetStartedSection = ({ onGetStarted }) => (
  <ColoredContainer
    backgroundColor="white"
    titleTx="refer:getStarted.title"
    titleColor="black"
    descriptionTx="refer:getStarted.description"
    descriptionColor="grey"
    descriptionVariant="p1"
    gap="s1"
    extraRowContent={
      <FlexContainer justifyContent="center" alignItems="center" marginTop="s2">
        <Button
          tx="refer:getStarted.getStartedBtn"
          width="20rem"
          onClick={onGetStarted}
        />
      </FlexContainer>
    }
  />
);
