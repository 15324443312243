import React from 'react';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Button } from 'core/button';
import { AmountSelectorProps } from 'modules/shop/select-gift-card/sections/amount-selector/amount-selector.props';
import { Grid } from 'layout/grid';
import giftCardsLocale from '../../../../../locales/en/gift-cards.json';
import { StyledAmountItem } from 'modules/shop/select-gift-card/sections/amount-selector/amount-selector.styles';

const amounts = Object.keys(giftCardsLocale.modal.steps.second.priceSubtitles);

export const AmountSelector = (props: AmountSelectorProps) => {
  const { selectedAmount, onAmountSelected, onPrevious, onNext } = props;

  return (
    <StackedContainer padding="unset" gap={{ lg: 's4', md: 's3' }}>
      <Typography
        variant="h3"
        tx="giftCards:modal.steps.second.title"
        align="center"
      />

      <Grid
        padding="unset"
        paddingTop={{ lg: 's2', md: 'unset' }}
        columns={2}
        gap="s1">
        {amounts.map((amount: string) => (
          <StyledAmountItem
            key={amount}
            selected={selectedAmount === +amount}
            onClick={() => onAmountSelected(+amount)}>
            <Typography
              text={`$${amount}`}
              align="center"
              color={selectedAmount === +amount ? 'primary' : 'black'}
            />

            <Typography
              tx={`giftCards:modal.steps.second.priceSubtitles.${amount}`}
              color="grey"
              align="center"
              variant="p3"
            />
          </StyledAmountItem>
        ))}
      </Grid>

      <Grid padding="unset" columns={2} gap="s1">
        <Button
          tx="giftCards:modal.steps.backBtn"
          variant="secondary"
          minWidth="unset"
          onClick={onPrevious}
        />

        <Button
          tx="giftCards:modal.steps.nextBtn"
          minWidth="unset"
          onClick={onNext}
        />
      </Grid>
    </StackedContainer>
  );
};
