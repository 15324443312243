import styled, { css } from 'styled-components';
import { media } from 'styles/media';

export const StyledAmountItem = styled.div<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    border: 1px solid ${selected ? theme.colors.primary : theme.colors.paper};
    border-radius: ${theme.borderRadius};
    padding: 3rem 4.5rem;
    cursor: pointer;
    height: 12.3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.lessThan('md')`
      padding: 2rem 1.2rem;
      height: 10rem;
    `}
  `}
`;
