import React from 'react';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { Typography } from 'core/typography';

export const InfoSection = () => (
  <Grid
    columns={{ lg: '1fr 5fr 1fr 4fr 1fr', md: '1fr' }}
    verticalPadding={{ lg: 's6', md: 's5' }}
    horizontalPadding={{ lg: 'unset', md: 's3', sm: 's1' }}
    backgroundColor="#ded9d4"
    gap="unset"
    rowGap="s3">
    <Cell column={{ lg: 2, md: 1 }}>
      <Typography
        variant="h2"
        tx="reach:info.title"
        color="opaqueGreen"
        textStyle="regular"
        maxWidth="45.5rem"
      />
    </Cell>
    <Cell column={{ lg: 4, md: 1 }}>
      <Typography
        tx="reach:info.description"
        color="opaqueGreen"
        whiteSpace="pre-line"
        textStyle="regular"
      />
    </Cell>
  </Grid>
);
