import React from 'react';
import ReactPlayer from 'react-player';
import { BoxContainer } from 'layout/box-container';

export const ReachVideoModal = ({ url }) => {
  return (
    <BoxContainer width="100%" height="100%" backgroundColor="black">
      <ReactPlayer
        url={url}
        controls={true}
        volume={0.5}
        width="100%"
        height="100%"
        playing={true}
      />
    </BoxContainer>
  );
};
