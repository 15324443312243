import styled from 'styled-components';
import { ImageContainer } from 'layout/image-container';

export const StyledIntroductionLogo = styled(ImageContainer)`
  top: 4rem;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
`;
