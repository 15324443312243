import styled from 'styled-components';
import { media } from 'styles/media';

export const StyledTablePreviewButtonContainer = styled.div`
  position: absolute;
  left: 12%;
  top: 45%;

  ${media.lessThan('md')`
    top: 65%;  
    left: 6%;
  `}
`;

export const StyledWallPreviewButtonContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 35%;

  ${media.lessThan('md')`
    top: 45%;   
    left: 35%;
  `}
`;

export const StyledFloorPreviewButtonContainer = styled.div`
  position: absolute;
  right: 20%;
  top: 48%;

  ${media.lessThan('md')`
    top: 65%;  
    right: 6%;
  `}
`;
