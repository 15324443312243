import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Page } from 'core/page';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { Grid } from 'layout/grid';
import { Cell } from 'layout/cell';
import { StyledCheckoutButtonContainer } from 'modules/shop/cart/cart.styles';
import { CartRecord, useCart } from 'context/cart.context';
import { GiftCardsBlock, ShipmentsBlock } from 'modules/shop/cart/sections';
import { Button } from 'core/button';
import { ShippingBannerSmaller } from 'core/shipping-banner-smaller/shipping-banner-smaller';
import { useGiftCode } from 'modules/shop/cart/hooks';
import { CartSummary } from 'layout/cart-summary';
import { useTheme } from 'styled-components';
import { Spacer } from 'layout/spacer';
import { CartProduct } from 'services/api/cart/cart-api.types';
import { api } from 'services/api';
import { GiftCodeInput } from 'core/gift-code-input';
import { useAuth } from 'context/auth.context';
import { CART_ITEMS, TEMPORAL_CART_INFO } from 'constants/common';
import { StyledFullPageContent } from 'layout/common';
import { usePaymentRequest } from 'src/common/hooks';
import { Icon } from 'core/icon';
import {
  PaymentRequestOptions,
  PaymentRequestShippingAddressEvent,
  PaymentRequestShippingOptionEvent,
  PaymentRequestTokenEvent,
} from '@stripe/stripe-js';
import {
  convertCartToAnalyticsCartViewEvent,
  convertCartToAnalyticsOrderCompletedEvent,
  convertPaymentRequestAddressToUserAddress,
  extractAnalyticsGiftCardDataFromRecord,
  extractCardInfoFromToken,
  extractNamePartsFromName,
  recalculateInStock,
} from 'modules/shop/cart/cart.adapters';
import {
  TransactionRequest,
  TransactionResponse,
} from 'services/api/payment/payment-api.types';
import { useNotification } from 'context/notification.context';
import { useRouter } from 'next/router';
import { trackAnalyticsEvent } from 'utils/analytics';
import { removeFromLocalStorage } from 'utils/storage';
import { usePrevious } from 'react-use';
import { extractAnalyticsProductDataFromSku } from 'modules/shop/product/product.adapter';
import { BoxContainer } from 'layout/box-container';
import { useMediaLarge } from 'utils/hooks';
import groupBy from 'lodash/groupBy';
import { GiftCardRecord } from 'types/gift-card.type';
import { convertGiftCardRecordsToGiftCardTransactionRecords } from 'modules/shop/checkout/checkout.adapters';
import { useCartHelpers } from 'modules/shop/cart/hooks/cart.hooks';

export const CartPage = () => {
  const [{ authenticated, campaignInfo, user }] = useAuth();
  const [
    {
      records,
      recordsInStock,
      recordsNotInStock,
      giftCards,
      leadTime,
      cartSummary,
      giftCode,
      shippingMethods,
    },
    cartDispatch,
  ] = useCart();

  const {
    updateCart,
    toggleIsGift,
    updateCartRecordGiftMessage,
  } = useCartHelpers();

  const { notify, notifyError } = useNotification();

  const [paymentTaxes, setPaymentTaxes] = useState(0);
  const [paymentShippingCost, setPaymentShippingCost] = useState(0);
  const router = useRouter();
  const { applyGiftCode } = useGiftCode();
  const previousTotal = usePrevious(cartSummary?.total || 0);
  const cartViewedRef = useRef(false);
  const isLarge = useMediaLarge();

  const groupedGiftCards = useMemo(() => {
    const temporal = groupBy(giftCards, 'sendDate');

    return {
      keys: Object.keys(temporal),
      data: temporal,
    };
  }, [giftCards]);

  const shippingInfoVisible = useMemo(
    () => records.length !== giftCards.length,
    [records, giftCards],
  );

  useEffect(() => {
    if (
      previousTotal &&
      cartSummary.total &&
      previousTotal !== cartSummary.total
    ) {
      trackAnalyticsEvent(
        'Order Updated',
        convertCartToAnalyticsCartViewEvent(cartSummary, records),
      );
    }
  }, [cartSummary.total, previousTotal]);

  const theme = useTheme();

  const shippingOptions = shippingMethods
    .filter(method => !recordsNotInStock?.length || method.type !== 'fastest')
    .map(method => ({
      id: method.type,
      amount: method.cost,
      label: `${method.provider} - ${method.option}`,
      detail: '',
    }));

  useEffect(() => {
    sessionStorage.removeItem(TEMPORAL_CART_INFO);

    cartDispatch({
      type: 'SET_TAX',
      value: undefined,
    });
  }, []);

  const processPaymentResponse = async (
    success: boolean,
    complete,
    data: TransactionResponse,
    error,
  ) => {
    if (success) {
      complete('success');

      sessionStorage.setItem(
        TEMPORAL_CART_INFO,
        JSON.stringify({
          orderId: data.data.id,
          recordsInStock,
          recordsNotInStock,
          giftCards,
          cartSummary: Object.assign({}, data.transaction?.amount, {
            giftDiscount: cartSummary?.giftDiscount,
          }),
        }),
      );

      cartDispatch({
        type: 'SET_GIFT_CODE',
        code: null,
      });

      removeFromLocalStorage(CART_ITEMS);

      notify(data.notice);

      trackAnalyticsEvent(
        'Order Completed',
        convertCartToAnalyticsOrderCompletedEvent(
          cartSummary,
          records,
          data.data.id,
        ),
      );

      await router.push(
        `/checkout?orderId=${data.data.id}&existing=${!!data.user?.data
          ?.hasPassword}`,
      );
    } else {
      notifyError(error?.message);

      complete('fail');
    }
  };

  useEffect(() => {
    if (records.length && !cartViewedRef.current) {
      trackAnalyticsEvent(
        'Cart Viewed',
        convertCartToAnalyticsCartViewEvent(cartSummary, records),
      );

      cartViewedRef.current = true;
    }
  }, [records.length, cartViewedRef]);

  const options = useMemo<PaymentRequestOptions>(
    () => ({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Gantri',
        amount: Math.max(
          cartSummary.subtotal -
            (cartSummary.giftDiscount || 0) -
            (cartSummary.giftDiscount ? 0 : cartSummary.credit || 0),
          0,
        ),
      },
      requestShipping: shippingInfoVisible,
      requestPayerEmail: true,
      requestPayerName: true,
      requestPayerPhone: true,
      displayItems: records.map(record => ({
        label: record.type === 'gift-card' ? record.design.title : record.name,
        amount:
          (record.type === 'gift-card' ? record.amount : record.price) || 0,
      })),
    }),
    [cartSummary.total],
  );

  const { paymentRequest, canMakePayment, applePay } = usePaymentRequest({
    options,
    onShippingOptionChanged: async ({
      shippingOption,
      updateWith,
    }: PaymentRequestShippingOptionEvent) => {
      const shippingAmount = records.length * shippingOption.amount;

      setPaymentShippingCost(shippingAmount);

      updateWith({
        status: 'success',
        shippingOptions,
        total: {
          label: 'Gantri',
          amount:
            cartSummary.subtotal +
            shippingAmount +
            paymentTaxes -
            cartSummary.giftDiscount -
            cartSummary.credit,
        },
      });
    },
    onShippingAddressChanged: async ({
      updateWith,
      shippingAddress: { city, region, country, postalCode },
    }: PaymentRequestShippingAddressEvent) => {
      const { success, data } = await api.user.fetchTaxes({
        address: {
          city,
          state: region,
          country,
          zip: postalCode,
        },
        items: records.map(record => ({
          type: record.type,
          sku: record.sku || '',
          amount: { subtotal: record.price || record.amount },
        })),
        gift: cartSummary.giftDiscount,
        giftCode: cartSummary?.giftCode ?? '',
        credit: cartSummary.credit,
      });

      const shippingAmount = applePay
        ? 0
        : records.length * (paymentShippingCost || 0);

      if (success) {
        updateWith({
          status: 'success',
          shippingOptions,
          total: {
            label: 'Gantri',
            amount:
              cartSummary.subtotal +
              shippingAmount +
              (data.data?.tax || 0) -
              cartSummary.giftDiscount -
              cartSummary.credit,
          },
        });

        setPaymentTaxes(data.data?.tax ?? 0);
      } else {
        updateWith({
          status: 'fail',
        });
      }
    },
    onTokenGenerated: async ({
      complete,
      payerEmail,
      payerName,
      token,
      shippingAddress,
      shippingOption,
    }: PaymentRequestTokenEvent) => {
      const address: any = {
        ...extractNamePartsFromName(payerName),
        ...convertPaymentRequestAddressToUserAddress(shippingAddress),
      };
      const total =
        cartSummary.subtotal +
        paymentShippingCost +
        paymentTaxes -
        (cartSummary.credit || 0) -
        (cartSummary.giftDiscount || 0);

      const request: TransactionRequest = {
        ...campaignInfo,
        payment: extractCardInfoFromToken(token),
        address,
        billingAddress: address,
        gift: giftCode || {},
        credit: cartSummary.credit,
        shipping: {
          type: shippingOption?.id,
        },
        items: records.filter(record => record.type !== 'gift-card'),
        giftCards: convertGiftCardRecordsToGiftCardTransactionRecords(
          giftCards,
        ),
        guest: !authenticated,
        amount: Object.assign({}, cartSummary, {
          tax: paymentTaxes,
          shipping: paymentShippingCost,
          total,
        }),
        userId: user?.id,
        email: payerEmail,
        analytics: true,
      };

      if (applePay) {
        const { success, data, error } = await api.payment.payWithApple({
          ...request,
          applePay: true,
          applePayToken: token.id,
        });

        await processPaymentResponse(success, complete, data, error);
      } else {
        const { success, data, error } = await api.payment.payWithGoogle({
          ...request,
          googlePay: true,
          googlePayToken: token.id,
        });

        await processPaymentResponse(success, complete, data, error);
      }
    },
    onCancel: () => {
      setPaymentShippingCost(0);
      setPaymentTaxes(0);
    },
  });

  const showPaymentDialog = () => {
    trackAnalyticsEvent(
      'Checkout Started',
      convertCartToAnalyticsCartViewEvent(cartSummary, records),
    );

    paymentRequest.update({
      currency: options.currency,
      total: options.total,
      shippingOptions: [],
    });

    paymentRequest.show();
  };

  const removeItem = async (product: CartProduct) => {
    let temporal = [...records];

    temporal.splice(product.originalPosition, 1);

    await updateCart(
      recalculateInStock(temporal, product as CartRecord),
      extractAnalyticsProductDataFromSku(product as any),
    );
  };

  const removeGiftCard = async (record: GiftCardRecord) => {
    const temporal = records.filter(
      (item: GiftCardRecord) => item.id !== record.id,
    );

    await updateCart(temporal, extractAnalyticsGiftCardDataFromRecord(record));
  };

  const analyticsCheckoutStartedData = convertCartToAnalyticsCartViewEvent(
    cartSummary,
    records,
  );

  return (
    <Page
      headerLocked={true}
      footerVisible={false}
      backgroundColor="cream"
      seoConfig={{ noindex: true }}>
      <StyledFullPageContent>
        <StackedContainer
          paddingTop={{ lg: 's5', sm: 's4' }}
          gap={{ lg: 's4', md: 's3' }}>
          <Typography type="h1" variant="h3" tx="cart:title" />

          <Grid padding="unset" columns={{ lg: '6fr 2fr 4fr', md: '1fr' }}>
            {!!records.length && (
              <Cell>
                <StackedContainer padding="unset" paddingBottom="s6" gap="s1">
                  {!!recordsInStock.length && (
                    <ShipmentsBlock
                      records={recordsInStock}
                      number={1}
                      inStock={true}
                      multiple={!!recordsNotInStock.length}
                      leadTime={leadTime}
                      giftable={true}
                      canEnterGiftMessage={true}
                      onRemove={removeItem}
                      onIsGiftChange={toggleIsGift}
                      onGiftMessageChange={updateCartRecordGiftMessage}
                    />
                  )}

                  {!!recordsNotInStock.length && (
                    <BoxContainer
                      marginTop={
                        !!recordsInStock.length
                          ? { lg: 'unset', md: '-3rem', sm: '-3rem' }
                          : 'unset'
                      }>
                      <ShipmentsBlock
                        records={recordsNotInStock}
                        number={2}
                        inStock={false}
                        multiple={!!recordsInStock.length}
                        leadTime={leadTime}
                        giftable={true}
                        canEnterGiftMessage={true}
                        onRemove={removeItem}
                        onIsGiftChange={toggleIsGift}
                        onGiftMessageChange={updateCartRecordGiftMessage}
                      />
                    </BoxContainer>
                  )}

                  {groupedGiftCards.keys.map((key: string) => (
                    <GiftCardsBlock
                      key={key}
                      records={groupedGiftCards.data[key]}
                      groupDate={key}
                      onRemove={removeGiftCard}
                    />
                  ))}

                  <Grid
                    hidden={{ lg: true, md: false }}
                    padding="unset"
                    gap="s2"
                    marginBottom="s1">
                    <CartSummary
                      summary={cartSummary}
                      affirmInfoVisible={!isLarge}
                      shippingInfoVisible={shippingInfoVisible}
                    />

                    <GiftCodeInput
                      value={giftCode?.code}
                      onApply={applyGiftCode}
                    />
                  </Grid>

                  <StyledCheckoutButtonContainer>
                    {canMakePayment && cartSummary.total > 0 && (
                      <Button
                        color="black"
                        text="Pay"
                        width="100%"
                        icon={
                          applePay ? (
                            <Icon
                              name="apple-pay"
                              color="white"
                              height="2rem"
                              top="-1px"
                            />
                          ) : (
                            <Icon name="google" color="white" height="2.1rem" />
                          )
                        }
                        analyticsEvent={
                          applePay
                            ? 'Initiate Checkout - Cart Checkout Apple Pay'
                            : 'Initiate Checkout - Cart Checkout Google Pay'
                        }
                        onClick={showPaymentDialog}
                      />
                    )}

                    <Button
                      tx="cart:checkoutBtn"
                      width="100%"
                      linkHref={
                        giftCode?.code
                          ? `/checkout?giftCode=${giftCode.code}`
                          : '/checkout'
                      }
                      analyticsEvent="Initiate Checkout - Cart Checkout"
                      analyticsEventData={analyticsCheckoutStartedData}
                    />
                  </StyledCheckoutButtonContainer>

                  <ShippingBannerSmaller />
                </StackedContainer>
              </Cell>
            )}

            {!records.length && (
              <Cell>
                <StackedContainer
                  verticalPadding={{ lg: 's3', sm: 's1' }}
                  backgroundColor="white"
                  borderRadius={theme.borderRadius}>
                  <Typography
                    tx="cart:emptyTitle"
                    type="h2"
                    variant="p2"
                    textStyle="bold"
                    align="center"
                  />

                  <Typography
                    tx="cart:emptyDescription"
                    variant="p2"
                    color="grey"
                    align="center"
                  />

                  <Spacer height="3.6rem" />

                  <Button
                    tx="cart:continueShoppingBtn"
                    width="100%"
                    linkHref="/shop"
                  />
                </StackedContainer>

                <Spacer height="s4" />

                <ShippingBannerSmaller />
              </Cell>
            )}

            {!!records.length && (
              <Cell column={{ lg: 3, md: 1 }} hidden={{ lg: false, md: true }}>
                <StackedContainer
                  alignContent="flex-start"
                  gap="s3"
                  padding="unset">
                  <CartSummary
                    summary={cartSummary}
                    affirmInfoVisible={isLarge}
                    shippingInfoVisible={shippingInfoVisible}
                  />
                  <GiftCodeInput
                    value={giftCode?.code}
                    onApply={applyGiftCode}
                  />
                </StackedContainer>
              </Cell>
            )}
          </Grid>
        </StackedContainer>
      </StyledFullPageContent>
    </Page>
  );
};
