import styled, { css } from 'styled-components';
import { FloatingContainerProps } from 'layout/floating-container/floating-container.props';
import { getThemeSpacing } from 'styles/theme';

export const StyledFloatingContainer = styled.div<FloatingContainerProps>`
  ${({ top, right, bottom, left }) => css`
    position: absolute;
    top: ${top ? getThemeSpacing(top) : 'unset'};
    right: ${right ? getThemeSpacing(right) : 'unset'};
    bottom: ${bottom ? getThemeSpacing(bottom) : 'unset'};
    left: ${left ? getThemeSpacing(left) : 'unset'};
  `}
`;
