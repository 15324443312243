import { Typography } from 'core/typography';
import { StackedContainer } from 'layout/stacked-container';
import React from 'react';
import { SizedContainer } from 'layout/sized-container';
import { StyledIntroductionLogo } from 'modules/shop/reach/sections/introduction/introduction.styles';

export const IntroductionSection = () => (
  <SizedContainer
    backgroundColor="opaqueGreen"
    aspectRatio="1:1"
    hidden={{ lg: true, md: false }}>
    <StackedContainer
      padding="unset"
      alignContent="center"
      justifyContent="center"
      justifyItems="center">
      <Typography tx="reach:hero.introduction" color="white" variant="h5" />
      <Typography
        tx="reach:hero.title"
        variant="h2"
        fontSize="3.6rem"
        lineHeight="4.8rem"
        color="white"
        textStyle="regular"
      />
      <Typography
        tx="reach:hero.description"
        variant="h3"
        color="white"
        textStyle="regular"
      />
    </StackedContainer>

    <StyledIntroductionLogo
      width="9rem"
      height="3rem"
      src="pages/home/logo-reach.png"
      backgroundColor="unset"
    />
  </SizedContainer>
);
