import { TextAreaProps } from './text-area.props';

export const TextAreaPresets: TextAreaProps = {
  id: '',
  rows: 5,
  labelColor: 'black',
  descriptionColor: 'grey',
  descriptionVariant: 'p3',
  placeholderColor: 'grey',
};
