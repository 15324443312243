import styled from 'styled-components';
import { ImageContainer } from 'layout/image-container';

export const StyledOpaqueImageContainer = styled(ImageContainer)`
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    opacity: 0.5;
  }
`;
