import React from 'react';
import { ImageContainer } from 'layout/image-container';
import { FlexContainer } from 'layout/flex-container';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { PhonePreviewProps } from 'modules/shop/select-gift-card/sections/phone-preview/phone-preview.props';
import { StyledCard } from 'modules/shop/gift-cards/sections/card-designs/card-designs.styles';
import { Icon } from 'core/icon';

export const PhonePreview = (props: PhonePreviewProps) => {
  const { message, amount, sender, photo } = props;

  return (
    <FlexContainer justifyContent="center" alignItems="center">
      <ImageContainer
        src="pages/gift-cards/phone.png"
        width={{ lg: '32rem', sm: '28rem' }}
        height={{ lg: '64.5rem', sm: '56.5rem' }}
        backgroundColor="unset">
        <StackedContainer
          horizontalMargin="s3"
          verticalMargin="s2"
          padding="unset"
          height={{ lg: '58rem', sm: '50.5rem' }}
          gap="s3"
          style={{
            overflow: 'auto',
          }}>
          <Typography
            tx="giftCards:phonePreview.heading"
            color="grey"
            align="center"
          />

          <Typography
            variant="p1"
            tx="giftCards:phonePreview.title"
            textStyle="bold"
            align="center"
            txValues={{ amount, sender }}
          />

          <StackedContainer padding="unset" gap="s2">
            <StyledCard>
              <ImageContainer
                src={photo}
                aspectRatio="1:0.65"
                source="external"
              />
            </StyledCard>

            <StackedContainer padding="unset" justifyItems="center" gap="5px">
              <Typography
                variant="h5"
                tx="giftCards:phonePreview.message"
                align="center"
                color="grey"
              />

              <Icon name="left-quote" color="lightGrey" width="2.5rem" />
            </StackedContainer>

            <Typography
              variant="p1"
              text={message}
              align="center"
              whiteSpace="pre-line"
            />
          </StackedContainer>
        </StackedContainer>
      </ImageContainer>
    </FlexContainer>
  );
};
