import React, { useCallback } from 'react';
import {
  StyledCard,
  StyledCloseButton,
  StyledGiftCardRow,
  StyledRightAlignedBlock,
  StyledRowContent,
  StyledSpecsContainer,
} from './gift-card-row.styles';
import { Typography } from 'core/typography';
import { formatCurrency } from 'utils/formaters';
import { Grid } from 'layout/grid';
import { StockAssociationStatus } from 'types/order.type';
import { ThemeColor } from 'styles/theme';
import { GiftCardRowProps } from 'layout/gift-card-row/gift-card-row.props';
import { ImageContainer } from 'layout/image-container';
import { Cell } from 'layout/cell';

const getStatusColor = (status: StockAssociationStatus): ThemeColor => {
  switch (status) {
    case StockAssociationStatus.Cancelled:
    case StockAssociationStatus.ReturnCanceled:
    case StockAssociationStatus.Refunded:
      return 'red';
    case StockAssociationStatus.Completed:
      return 'black';
    default:
      return 'grey';
  }
};

export const GiftCardRow = (props: GiftCardRowProps) => {
  const { data, removable, onRemove } = props;

  const onRemoveClick = useCallback(
    event => {
      event.preventDefault();
      onRemove(data);
    },
    [onRemove, data],
  );

  return (
    <Grid padding="unset" gap="s2">
      <StyledGiftCardRow>
        <StyledCard>
          <ImageContainer
            src={data.design?.photo}
            source="external"
            width={{ lg: '8rem', sm: '6rem' }}
            aspectRatio="1:0.7"
          />
        </StyledCard>

        <StyledRowContent removable={removable}>
          <StyledSpecsContainer>
            <Typography text={data.design?.title} />
            <Grid padding="unset" columns="max-content 1fr" rowGap="unset">
              <Cell>
                <Typography tx="cart:sender" color="grey" />
              </Cell>
              <Cell>
                <Typography text={data.senderName} />
              </Cell>

              <Cell>
                <Typography tx="cart:recipient" color="grey" />
              </Cell>
              <Cell>
                <Typography
                  text={`${data.recipientName}, ${data.recipientEmail}`}
                />
              </Cell>

              <Cell>
                <Typography tx="cart:message" color="grey" />
              </Cell>
              <Cell>
                <Typography text={data.message} />
              </Cell>

              <Cell hidden={{ lg: true, sm: false }}>
                <Typography text={formatCurrency(data.amount)} color="grey" />
              </Cell>
            </Grid>
          </StyledSpecsContainer>

          <StyledRightAlignedBlock>
            <Typography text={formatCurrency(data.amount)} color="grey" />
          </StyledRightAlignedBlock>

          {removable && (
            <StyledCloseButton
              name="close"
              onClick={onRemoveClick}
              color="lightGrey"
              size="1.2rem"
            />
          )}
        </StyledRowContent>
      </StyledGiftCardRow>
    </Grid>
  );
};
