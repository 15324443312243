import styled, { css } from 'styled-components';
import { Thumbnail } from 'core/thumbnail';
import { media } from 'styles/media';

export const StyledHeroContainer = styled.div`
  position: relative;
`;

export const StyledHeroImageSelector = styled.div`
  ${({ theme }) => css`
    background-color: ${theme.colors.translucentBlack};
    padding: 1.6rem ${theme.spacing.s3} 0.7rem;
    width: 100%;
    z-index: 2;
    bottom: 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, 7.2rem);
    gap: ${theme.spacing.s1};
    align-items: center;
    align-content: center;

    ${media.lessThan('md')`
      padding: ${theme.spacing.s1} ${theme.spacing.s1} .6rem;
      row-gap: .6rem;
    `}
  `}
`;

export const StyledThumbnail = styled(Thumbnail)`
  cursor: pointer;
`;

export const StyledAddThumbnail = styled.div`
  ${({ theme }) => css`
    cursor: pointer;
    width: 7.2rem;
    height: 4.8rem;
    border: 1px dashed ${theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -3px;

    ${media.lessThan('sm')`
      width: 6rem;
      height: 4rem;
    `}
  `}
`;

export const StyledRemoveButton = styled.div`
  position: absolute;
  z-index: 2;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
`;

export const StyledAddHeroPhoto = styled.div`
  ${({ theme }) => css`
    display: grid;
    row-gap: ${theme.spacing.s1};
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-bottom: 7.2rem;
  `}
`;

export const StyledEmptyCard = styled.div`
  ${({ theme }) => css`
    width: 40vw;
    height: calc(40vw * 0.66);
    border: 1px dashed ${theme.colors.lightGrey};
    margin-bottom: ${theme.spacing.s3};
    margin-left: ${theme.spacing.s3};
    display: grid;
    align-items: center;
    text-align: center;
    cursor: pointer;

    ${media.lessThan('sm')`
      width: 70vw;
      height: calc(70vw * 0.66);
      margin-bottom: ${theme.spacing.s3};
      margin-left: ${theme.spacing.s1};
    `}
  `}
`;
