import React, { useCallback, useRef, useState } from 'react';
import { ImageContainer } from 'layout/image-container';
import { StyledHeroContainer } from 'modules/shop/designer/sections/behind-the-scenes-edit/behind-the-scenes-edit.styles';
import { StackedContainer } from 'layout/stacked-container';
import { Typography } from 'core/typography';
import { ScrollableList } from 'core/scrollable-list';
import { BoxContainer } from 'layout/box-container';
import { ListScroll } from 'layout/list-scroll';
import { OnListScrollEvent } from 'core/scrollable-list/scrollable-list.props';

export const BehindTheScenesSection = ({ images }) => {
  const scrollRef = useRef();
  const [scrollData, setScrollData] = useState<OnListScrollEvent>(null);

  const renderItem = useCallback(
    (position: number) => {
      return (
        <ImageContainer
          src={images[position]}
          source="external"
          aspectRatio="1:.66"
        />
      );
    },
    [images],
  );

  return (
    <StackedContainer
      backgroundColor="paper"
      paddingTop={{ lg: 's6', md: 's5' }}
      paddingBottom={{ lg: 's5', md: 's3' }}
      horizontalPadding="unset"
      gap={{ lg: 's5', md: 's3' }}>
      <Typography variant="h2" text="Behind the Scenes" align="center" />

      <StyledHeroContainer>
        <ScrollableList
          ref={scrollRef}
          itemsCount={images.length}
          renderItem={renderItem}
          itemsPerPage={{ lg: 2.5, md: 1.5 }}
          gapVisible={true}
          gap={{ lg: '3rem', md: 's1' }}
          hasHorizontalPadding={true}
          mode="scroll"
          onListScroll={setScrollData}
        />

        <BoxContainer
          marginTop={{ lg: 's3', md: 's2', sm: 's1' }}
          marginBottom={{ lg: 's5', md: 's3' }}
          horizontalPadding={{ lg: 's3', md: 's1' }}>
          <ListScroll
            listRef={scrollRef}
            scrollData={scrollData}
            itemsCount={images.length}
          />
        </BoxContainer>
      </StyledHeroContainer>
    </StackedContainer>
  );
};
